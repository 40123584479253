import cn from "classnames";
import { useContext, useState } from "react";

import { isValidURL } from "utils/onboarding.utils";

import { Button } from "shared/components/buttons/Button";

import {ReactComponent as CompanyProfileIcon} from "assets/icons/company-profile.svg";
import {ReactComponent as RefreshIcon} from "assets/icons/refresh-primary.svg";

import { SetupCompanyProfileContext } from "../SetupCompanyProfile";

const InputContainer = ()=>{
    
    const [checkedOnce ,setCheckedOnce] = useState<boolean>(false)
    const [error ,setError] = useState<string>("")
    const {handleImport, loading, showRestartButton, updateShowRestartButton, url, updateUrl} = useContext(SetupCompanyProfileContext)
    const handleSubmit = ()=>{
        setCheckedOnce(true)
    if(url.length === 0){
      setError("URL is required!")
      return
    }
    if(isValidURL(url.trim())){
      // Hit Api here
      updateShowRestartButton(true)
      handleImport(url)
    }else{
      setError("Invalid URL! Please enter a valid URL.")
    }
    }
    const handleChangeUrl = (e: React.ChangeEvent<HTMLInputElement>)=>{
        updateUrl(e.currentTarget.value)
        if(checkedOnce){
            if(e.currentTarget.value.length === 0){
                setError("URL is required!")
            }else if(!isValidURL(e.currentTarget.value.trim())){
                setError("Invalid URL! Please enter a valid URL.")
            }else{
                setError("")
            }
        }
    }
    return <div className="p-6 bg-white rounded-2xl flex gap-x-16 flex-col gap-y-4 lg:flex-row lg:gap-y-0">
        <div className="flex items-center gap-x-4 ">
        <CompanyProfileIcon className="h-10 w-10"/>
        <div>
            <p className="text-xl font-bold text-[#14191A]">Company Profile</p>
            <p className="text-base font-medium text-[#717684]">{"To get started, please provide your company's URL."}</p>
        </div>
        </div>
        <div className="flex items-center flex-1 mt-1.5">
        <div className={cn("w-full bg-white px-[14px] py-2 rounded-xl flex items-center border relative",{"border-[#E36C74]":error})}>
            <input type={"text"} value={url} className={"w-full focus:outline-none"} onChange={handleChangeUrl} placeholder={"Example: brainvoy.ai"} />
          <span className="absolute left-4 -bottom-5 text-red-700 text-sm">{error}</span>
          </div>
          {showRestartButton?<button className=" !w-fit  rounded-xl ml-4 bg-white border-0 flex items-center gap-x-2.5 text-sm text-purple font-semibold" disabled={loading} onClick={handleSubmit}>
            <RefreshIcon className="h-5 w-5" />
            <p>Restart</p>
        </button>:<Button className=" !w-fit  rounded-xl ml-4" disabled={loading} onClick={handleSubmit}>
            <p>Import</p>
        </Button>}
        </div>
    </div>
}

export default InputContainer;