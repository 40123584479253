import { motion } from "framer-motion";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";

import { AgentItem } from "api/agent/type";

import { useAppSelector } from "hooks/appHooks";

import Emma from "assets/png/emma.png";

import { cn } from "lib/utils";

import { Button } from "../buttons/Button";
import { Input } from "../form-elements/Input";
import { Icons } from "../icons";

import { useAI } from "./provider";

interface Props {
  className?: string;
  asSheet?: boolean;
}

export default function AIPanel({ ...props }: Props) {
  if (props.asSheet == true) {
    return (
      <div className='absolute top-0 right-0 z-50 h-full pt-5'>
        <AIPanelContent {...props} className={cn(props.className, " shadow-ai-panel rounded-tl-2xl overflow-hidden")} />
      </div>
    );
  }

  return <AIPanelContent {...props} />;
}

const AIPanelContent = ({ className }: Props) => {
  const { page, switchPage, showAsSheet, closePanel } = useAI();

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [showScrollButton, setShowScrollButton] = useState(false);

  const agents = useAppSelector(state => state.agent.agents);

  const emmaAgent = useMemo(() => {
    if (!agents) return null;

    return agents.find((agent: AgentItem) => agent.id === 1);
  }, [agents]);

  const handleCloseSheet = useCallback(() => {
    closePanel();
  }, [showAsSheet]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleScroll = () => {
    if (chatContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
      const isNearBottom = scrollHeight - scrollTop - clientHeight < 100;
      setShowScrollButton(!isNearBottom);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  return (
    <div
      className={cn(
        "h-full  border-l-2 border-[#f7f9fb] bg-white",
        className,
        showAsSheet ? "w-[600px] max-w-[650px]" : "w-[750px] max-w-[800px]",
      )}
    >
      <div className='relative flex flex-col h-full w-full justify-between'>
        <div className='absolute w-full'>
          <div className='flex flex-col flex-shrink-0 bg-white border-b border-[#E9EAEC]'>
            <div className='flex items-center justify-between px-6 py-3.5 h-[4rem] border-b-2 border-[#f7f9fb]'>
              <div className='flex items-center gap-2 '>
                <img src={Emma} alt={emmaAgent?.name} className='w-[32px] h-[32px] rounded-full' />
                <h3 className='text-[#1F2228] font-bold text-lg'>{emmaAgent?.name}</h3>
                <span className='rounded-[22px] bg-[#FFE493] font-semibold text-[#8F6E0A] px-[10px] text-[11px] uppercase flex items-center justify-center'>
                  Beta
                </span>
              </div>
              {showAsSheet && (
                <button className='' onClick={handleCloseSheet}>
                  <Icons.CloseSquare />
                </button>
              )}
            </div>
            <div className='px-6 py-1.5 flex gap-4 items-center'>
              {["active", "history"].map(item => (
                <button
                  key={item}
                  onClick={() => switchPage(item as "active" | "history")}
                  className={cn(
                    "!px-6 !py-1.5  !font-semibold !text-sm !w-auto  capitalize !rounded-lg",
                    page === item ? "!bg-[#F1F0FC] !text-purple" : "!bg-transparent text-[#1F2228] before:!border-none",
                  )}
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
          {page === "active" && <div className='bg-ai-panel-gradient h-[24px]  w-full'></div>}
        </div>
        {page === "active" && (
          <>
            <div
              className='h-full w-full overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-600 '
              ref={chatContainerRef}
              onScroll={handleScroll}
            >
              <div className='flex flex-col gap-4 w-full mt-28 mb-4'>
                {Array.from({ length: 20 }).map((_, index) => (
                  <ChatMessage key={index} isAI={index % 2 === 0} isSuggestion={index == 8} />
                ))}
                <div ref={messagesEndRef} />
              </div>

              {showScrollButton && (
                <div>
                  <Button
                    onClick={scrollToBottom}
                    className='absolute bottom-44 right-8 !h-[40px] !w-[40px] rounded-full p-2 before:!border-none'
                  >
                    <Icons.ArrowDown className='h-4 w-4 text-white' />
                  </Button>
                </div>
              )}
            </div>

            <div className='mx-6 mb-4 bg-[#F7F7F8] rounded-2xl p-4'>
              <textarea
                placeholder='Ask me anything...'
                autoCorrect='false'
                autoComplete='false'
                data-custom='no-ai'
                className='relative resize-none bg-transparent w-full focus:ring-0 focus:ring-offset-0 outline-none border-none text-sm text-[#1F2228] font-medium placeholder:text-[#717684] min-h-[10px]'
              />
              <div className='flex w-full justify-between'>
                <div className='flex gap-2'>
                  <button>
                    <Icons.Attachment className='text-[#C8CAD0]' />
                  </button>
                  <button>
                    <Icons.Image className='text-[#C8CAD0]' />
                  </button>
                  <Tooltip
                    id='chat-question-tooltip'
                    positionStrategy='fixed'
                    place='top'
                    opacity={1}
                    style={{
                      borderRadius: "13px",
                      background: "#2E323A",
                      boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.20)",
                      zIndex: 1000,
                      fontSize: "12px",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>Browse questions</span>
                    </div>
                  </Tooltip>
                  <button data-tooltip-id='chat-question-tooltip'>
                    <Icons.QuestionSquare className='text-[#717684]' />
                  </button>
                </div>
                <Button className='!p-[7px] !bg-[#E9EAEC] !rounded-full before:!border-none !w-auto'>
                  <Icons.Send className='!text-[#C8CAD0]' />
                </Button>
              </div>
            </div>
          </>
        )}
        {page === "history" && (
          <div className='h-full w-full flex flex-col gap-4 mt-28 mb-4'>
            <div className='mx-6 mt-4'>
              <Input placeholder='Search' icon={Icons.Search02} />
            </div>
            <div className='text-[#1F2228] mx-6 flex flex-col gap-3'>
              <div className='flex flex-col gap-3'>
                <h3 className='text-sm font-medium '>Today</h3>
                <ul className='flex flex-col'>
                  <li className='font-normal text-xs h-[32px] pl-1.5'>Content Strategy for Social Media Campaign</li>
                  <li className='font-normal text-xs bg-[#F7F7F8] rounded-lg h-[32px] justify-center flex flex-col pl-1.5'>
                    Content Strategy for Social Media Campaign
                  </li>
                </ul>
              </div>
              <div className='h-[2px] bg-[#F7F7F8] w-full'></div>
              <ChatHistoryGroup />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

interface ChatMessageProps {
  isAI?: boolean;
  isSuggestion?: boolean;
}

const ChatMessage = ({ isAI, isSuggestion }: ChatMessageProps) => {
  return (
    <div className={cn("flex flex-row w-full max-w-full items-end gap-2 px-6", isAI == true ? "justify-start " : "justify-end")}>
      {isAI == true && <img src={Emma} alt='Emma' className='w-[32px] h-[32px] rounded-full' />}
      {isSuggestion == true ? (
        <div className='flex flex-col gap-2'>
          <p className='text-[#464A53] text-xs font-medium'>Suggested goals</p>
          <div className='flex flex-col gap-1 w-full'>
            <button className='flex items-center text-sm font-medium border border-[#E9EAEC] py-2 pr-8 pl-4 rounded-3xl bg-white w-auto'>
              <Icons.Plus className='mr-1' />
              Increasing website traffic by 40% this quarter
            </button>
            <button className='flex items-center text-sm font-medium border border-[#E9EAEC] py-2 pr-8 pl-4 rounded-3xl bg-white w-auto'>
              <Icons.Plus className='mr-1' />
              Improve customer retention by 20%
            </button>
            <button className='flex items-center text-sm font-medium border border-[#E9EAEC] py-2 pr-8 pl-4 rounded-3xl bg-white w-auto'>
              <Icons.Plus className='mr-1' />
              Increasing your annual revenue by 30% this year
            </button>
          </div>
        </div>
      ) : (
        <div className={cn(" px-4 py-2 rounded-2xl", isAI == true ? "bg-[#F7F7F8]" : "bg-[#F1F0FC]")}>
          <p className='text-[#1F2228] text-sm font-medium'>Hi</p>
        </div>
      )}
    </div>
  );
};

const ChatHistoryGroup = () => {
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);

  return (
    <div className='flex flex-col gap-3'>
      <h3 className='text-sm font-medium '>Yesterday</h3>
      <ul className='flex flex-col relative'>
        <motion.div
          className='absolute bg-[#F7F7F8] rounded-lg w-full h-[32px]'
          initial={false}
          animate={{
            y: hoveredItem !== null ? hoveredItem * 32 : 0,
          }}
          transition={{ type: "spring", stiffness: 500, damping: 30 }}
        />
        {Array.from({ length: 5 }).map((_, index) => (
          <li
            key={index}
            onMouseEnter={() => setHoveredItem(index)}
            onMouseLeave={() => setHoveredItem(null)}
            className='relative z-10 cursor-pointer font-normal text-xs h-[32px] justify-center flex flex-col pl-1.5'
          >
            Content Strategy for Social Media Campaign
          </li>
        ))}
      </ul>
    </div>
  );
};
