import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dispatch } from "redux";

import { createProject, getAllProjects, getProjectById, uploadFile } from "api/projects";
import { ICreateProjectData } from "api/projects/types";

import Alert from "shared/components/toasts";

import { setPoject, updateLoader } from "../slices/project-slice";

export const PROJECTS_SLICE_NAME = "projeects";

export const createProjectAction = (data: ICreateProjectData) => async (dispatch: Dispatch) => {
  try {
    return createProject(data);
  } catch (e: any) {
    const errorMessage = (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
    Alert("error", "Something went wrong", "Error");
  }
};

// export const createBudgetAction = (data: ICreateBudget) => async (dispatch: Dispatch) => {
//   try {
//     return createBudget(data);
//   } catch (e: any) {
//     const errorMessage =
//       (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
//     Alert('error', 'Something went wrong', 'Error');
//   }
// };

export const getOneProject = (id: number, callBack?: any) => async (dispatch: Dispatch) => {
  try {
    dispatch(updateLoader(true));
    const project = await getProjectById(id);
    dispatch(setPoject(project));
    dispatch(updateLoader(false));
    return project;
  } catch (e: any) {
    callBack("error");
    dispatch(updateLoader(false));
    Alert("error", e?.message, "Error");
  }
};

export const getProjectsList = createAsyncThunk(`${PROJECTS_SLICE_NAME}/getProjectsList`, ({ page }: { page: number }) => {
  return getAllProjects(page);
});

export const uploadFileAction = (file: File) => async (dispatch: Dispatch) => {
  try {
    const response = await uploadFile(file);
    return response;
  } catch (e: any) {
    const errorMessage = (e.response && e.response.data && e.response.data.message) || e.message || e.toString();
    Alert("error", "Something went wrong", "Error");
  }
};
