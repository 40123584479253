import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getConnectedData } from "api/integrations";

import { ROUTES } from "constants/routes.constants";

import { useAppDispatch } from "hooks/appHooks";

import { setIsCollapsed } from "storage/slices/sidebar-slice";

import { Breadcrumbs } from "shared/components/breadcrumbs/Breadcrumbs";
import SpinLoader from "shared/components/loader-screen/SpinLoader";
import Alert from "shared/components/toasts";
import { DashboardLayout } from "shared/layouts/DashboardLayout";

import ConnectionStatus from "./components/ConnectionStatus";

const SourceDetails = () => {
  const [loading, setLoading] =useState(true)

  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [connectedData,setConnectedData] = useState<any>(null)
    const getBreadCrumbs = () => [
        { path: ROUTES.connectedSources, label: "Connected Sources", active:false },
        { path: "", label: connectedData?.account_name, active:true },
      ];
      const getConnectedSource = useCallback(()=>{
        // get connected source data
        if(!id) return
        setLoading(true)
        getConnectedData(id).then((res:any)=>{
          if(res.statusCode === 200){
            setConnectedData(res.data)
          }
        }).catch(err=>{
          Alert("error",err?.message,"Error")
          console.log("Error in getConnectedData",err)
        }).finally(()=>{
          setLoading(false)
        })
      },[])
      useEffect(()=>{
        if(id) getConnectedSource()
      },[id])
   
  return (
    <DashboardLayout title='Integrations'>
        <div className="py-6 px-5">
        <Breadcrumbs breadcrumbs={getBreadCrumbs()} />
        {!loading?<ConnectionStatus connectedData={connectedData} getConnectedSource={getConnectedSource}/> :
        <div className="w-full flex justify-center mt-10">
          <SpinLoader className="w-8 h-8"/>
        </div>}

      </div>
    </DashboardLayout>
  );
};

export default SourceDetails;
