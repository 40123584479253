import cn from "classnames";
import React from "react";
import { twMerge } from "tailwind-merge";

import OperationalFakeDoor from "shared/components/popups/operationalFakeDoor/OperationalFakeDoor";

import { ReactComponent as CalendarIcon } from "assets/icons/calendar-2.svg";
import { ReactComponent as DocumentIcon } from "assets/icons/document-text.svg";
import { ReactComponent as TemplateIcon } from "assets/icons/document.svg";
import { ReactComponent as ArrowDown } from "assets/icons/selection-down-arrow.svg";
import barChart from "assets/png/bar-chart.png";
import consumerTable from "assets/png/consumer-build-table.png";
import heatMap from "assets/png/heat-map.png";

const verticalColumnFields = [
  {
    name: "Revenue projections",
    active: false,
  },
  {
    name: "Win-loss analysis",
    active: false,
  },
  {
    name: "Customer Cohort analysis",
    active: true,
  },
  {
    name: "Multi-entity P&L",
    active: false,
  },
  {
    name: "Branded vs non-branded CPL",
    active: false,
  },
  {
    name: "Marketing-Driven SaaS",
    active: false,
  },
  {
    name: "Customer retention funnel",
    active: false,
  },
];

const OperationalView = () => {
  return (
    <div className='flex h-full w-full relative bg-white'>
      <div className='flex flex-col justify-between py-6 px-5 border-r-2 border-[#F7F9FB] min-w-[272px] blur-[1px]'>
        <div>
          <h3 className='font-medium text-sm leading-default text-[#717684]'>Select template</h3>
          <div className='mt-4 flex flex-col gap-2 w-full'>
            {verticalColumnFields.map((column, ind) => (
              <div
                key={ind}
                // className='p-3 flex items-center gap-3 border-[1px] rounded-[11px] font-semibold leading-default text-xs text-[#333740] border-[E9EAEC]'
                className={twMerge(
                  cn(
                    "p-3 flex items-center gap-3 border-[1px] rounded-[11px] font-semibold leading-default text-xs text-[#333740] border-[E9EAEC]",
                    {
                      "bg-[#F9F9FF] border-[#C0BBF3]": column?.active,
                    },
                  ),
                )}
              >
                <TemplateIcon />
                {column?.name}
              </div>
            ))}
          </div>
        </div>
        <button disabled className='py-[10px] px-6 rounded-xl bg-[#E9EAEC] text-sm font-semibold leading-default text-[#C8CAD0]'>
          Select
        </button>
      </div>
      <div className='flex flex-col blur-[1px] p-6 gap-4'>
        <div className='flex items-center justify-between '>
          <div className='flex items-center gap-3'>
            <DocumentIcon />
            <h2 className='font-bold text-[#333740] leading-default text-2xl'>Customer Cohort Analysis Template</h2>
          </div>
        </div>
        <div className='pt-0 text-sm font-medium leading-6 text-gray-600 max-md:max-w-full '>
          This is a template model for a software company with multiple products. Its revenue growth is driven by marketing spend.
          <br />
          <br />
          Using the flexibility of Categories allows you to specify the values of a variable like MRR per product, Product split, and
          Contract length (months) by Product. Importantly, you can change/add products included in the Product category by using the data
          table at the bottom of the screen.
          <br />
          <br />
          Unlike other spreadsheet solutions, Brainvoy requires one formula per variable to calculate the total company revenue across each
          Product line. However - if you want to manually set different values/formulas for each product (e.g. having a different CAC input
          for each product) you can explicitly apply the product category to a variable.{" "}
        </div>
        <div className='flex gap-6'>
          <div className='rounded-2xl w-1/2 border-[1px] border-[#E9EAEC] overflow-hidden'>
            <img className='object-cover w-full aspect-auto' src={heatMap} />
          </div>

          <div className='rounded-2xl w-1/2 border-[1px] border-[#E9EAEC] overflow-hidden'>
            <img className='object-cover w-full aspect-auto' src={barChart} />
          </div>
        </div>
        <div className='w-full'>
          <img className='w-full object-cover' src={consumerTable} />
        </div>
      </div>
      <div className='h-full w-full fixed flex flex-col justify-center items-center'>
        <div className='flex flex-col justify-center hs-overlay-open:mt-7 hs-overlay-open:duration-500 opacity-1 ease-out transition-all max-w-[814px] w-fit'>
          <OperationalFakeDoor />
        </div>
      </div>
    </div>
  );
};

export default OperationalView;
