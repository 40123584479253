import React, { useState } from "react";

// Custom number editor component
const TextOnlyInput: React.FC<any> = props => {
  const { row, column, onRowChange, onClose } = props;
  const [value, setValue] = useState(row[column.key])
  const [focused, setFocused] = useState(0)
  const inputRef = React.useRef<HTMLInputElement>(null);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    console.log("Column",column)
    if(row.isEditableRow && column.key === "Channel"){
        onRowChange({ ...row, "name": value ===""?"":value });
    } else if (value === "" || /^\d*$/.test(value)) {
    setValue(value)

      onRowChange({ ...row, [column.key]: value ===""?0:value });
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      inputRef.current?.blur()
    }
  };
  return (
    <div className="p-2 text-[#5E636E] text-sm font-medium dollar-only-input">
      { row.isEditableRow ? "" : "$"}
    <input
    ref={inputRef}
      className={"custom-text-editor-table text-[#5E636E] text-sm font-medium border-0 z-50"}
      value={value}
      onKeyDown={handleKeyDown}
      placeholder="Add Channel Name"
      autoFocus={column.key === "Channel"?false:true}
      
      onChange={handleChange}
      onClick={(e: React.MouseEvent<HTMLInputElement>) =>{ 
        const activeElement = document.activeElement as HTMLElement | null;
        activeElement?.blur();
        
        setTimeout(() => {
          (e.target as HTMLInputElement).focus();
        }, 20);
        
      }}
    />
    </div>
  );
};

export default TextOnlyInput;
