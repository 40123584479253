import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dispatch } from "redux";

import { getAllAgents } from "api/agent";

export const AGENT_SLICE_NAME = "agents";

export const getAgentsList = createAsyncThunk(`${AGENT_SLICE_NAME}/getAgentsList`, ({ page }: { page: number }) => {
  return getAllAgents(page);
});
