import React, { useState } from "react";

// Custom number editor component
const NumberEditor: React.FC<any> = props => {
  const { row, column, onRowChange, onClose } = props;
  const [value, setValue] = useState(row[column.key])
  const inputRef = React.useRef<HTMLInputElement>(null);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    console.log("Column",column)
    if(row.isEditableRow && column.key === "Channel"){
        onRowChange({ ...row, "name": value ===""?"":value });
    } else if (value === "" || /^\d*$/.test(value)) {
    setValue(value)

      onRowChange({ ...row, [column.key]: value ===""?0:value });
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      inputRef.current?.blur()
    }
  };
  return (
    <div className="p-2 text-[#5E636E] text-sm font-medium dollar-only-input">
      { row.isEditableRow ? "" : "$"}
    <input
    ref={inputRef}
      className={"custom-text-editor-table text-[#5E636E] text-sm font-medium border-0 z-50"}
      value={value}
      onKeyDown={handleKeyDown}
      autoFocus={column.key === "Channel"?false:true}
      onFocus={(e: any) =>{ 
        if(!row.isEditableRow || column.key !== "Channel"){
          e.target.select()
        }
      }}
      onChange={handleChange}
      onBlur={() => {
        onClose?.(true, false)
      }}
      // onClick={(e) => e.stopPropagation()}
    />
    </div>
  );
};

export default NumberEditor;
