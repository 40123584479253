import React from "react"

import BlurOverlay from "shared/components/BlurOverlay"

import EmmaAIIcon from "assets/png/emma-ai-agent.png";


type Props = {
    isShown:boolean
}
const LoadingOverlay = ({isShown}:Props) => {
    return <BlurOverlay isShown={isShown} className="!bg-white/90">
        <div className="h-full w-full flex justify-center items-center flex-col gap-8 p-6">
          <div className="flex gap-2">
            <div className="h-1.5 w-1.5 bg-purple rounded-full animate-pulse"/>
            <div className="h-1.5 w-1.5 bg-purple rounded-full animate-pulse"/>
            <div className="h-1.5 w-1.5 bg-purple rounded-full animate-pulse"/>
          </div>
          <div className="flex items-center gap-4">
        <img src={EmmaAIIcon} className ="w-8 h-8 rounded-full"  />
        <p className="bg-[#F7F7F8] px-4 py-1.5 rounded-xl font-medium text-sm text-[#1F2228]">Just a moment, preparing your insights</p>
        </div>
        </div>
        </BlurOverlay>
}
export default LoadingOverlay