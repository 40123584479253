import React, { useState } from "react";

import { Icons } from "shared/components/icons";

import { cn } from "lib/utils";

interface IGoalItemProps {
  children: React.ReactNode;
  className?: string;
  checked?: boolean;
  onChecked?: (value: boolean) => void;
}

export default function GoalItem({ children, className, checked, onChecked }: IGoalItemProps) {
  const [value, setValue] = useState(checked || false);

  const handleClick = () => {
    setValue(!value);
    onChecked && onChecked(!value);
  };

  return (
    <button
      onClick={handleClick}
      type='button'
      className={cn(
        "flex gap-3 items-center justify-center border  py-2.5 pl-4 pr-6 rounded-[56px] bg-white",
        checked == true ? "border-[#A39DED] drop-shadow-sm" : "border-[#E8E9F0]",
        className,
      )}
    >
      <span
        className={cn(
          " h-5 w-5 border rounded-full flex items-center justify-center",
          checked == true ? "bg-purple border-purple " : "bg-white border-[#E9EAEC] ",
        )}
      >
        {checked == true && <Icons.Check className='text-white' />}
      </span>
      {children}
    </button>
  );
}
