import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";

import { Strategy, Workflow, WorkflowListItem } from "interfaces/workflows.interfaces";

import {
  createWorkflow,
  generateWorkflow,
  getAllWorkflows,
  getWorkflow,
  getWorkflowsStrategiesList,
  setIsWorkflowsLoaded,
  setWorkflow,
} from "../actions/workflows";
import { WorkflowsState } from "../slices/workflows-slice";

export function getWorkflowReducer(builder: ActionReducerMapBuilder<WorkflowsState>) {
  builder.addCase(getWorkflow.pending, state => {
    state.isWorkflowLoading = true;
  });

  builder.addCase(getWorkflow.fulfilled, (state, action: PayloadAction<Workflow>) => {
    state.workflow = action.payload;
    state.isWorkflowLoading = false;
  });

  builder.addCase(getWorkflow.rejected, state => {
    state.isWorkflowLoading = false;
  });
}

export function getAllStrategyReducer(builder: ActionReducerMapBuilder<WorkflowsState>) {
  builder.addCase(getWorkflowsStrategiesList.pending, state => {
    state.isStrategyLoading = true;
  });

  builder.addCase(getWorkflowsStrategiesList.fulfilled, (state, action: PayloadAction<Strategy[]>) => {
    state.strategies = action.payload;
    state.isStrategyLoading = false;
  });

  builder.addCase(getWorkflowsStrategiesList.rejected, state => {
    state.isStrategyLoading = false;
  });
}

export function generateWorkflowReducer(builder: ActionReducerMapBuilder<WorkflowsState>) {
  builder.addCase(generateWorkflow.pending, state => {
    state.isGeneratingContent = true;
  });

  builder.addCase(generateWorkflow.fulfilled, (state, action: PayloadAction<Workflow>) => {
    state.workflow = action.payload;
    state.isGeneratingContent = false;
  });

  builder.addCase(generateWorkflow.rejected, state => {
    state.isGeneratingContent = false;
  });
}
export function creatingWorkflowReducer(builder: ActionReducerMapBuilder<WorkflowsState>) {
  builder.addCase(createWorkflow.pending, state => {
    state.isGeneratingContent = true;
  });

  builder.addCase(createWorkflow.fulfilled, state => {
    state.isGeneratingContent = false;
  });

  builder.addCase(createWorkflow.rejected, state => {
    state.isGeneratingContent = false;
  });
}

export function setWorkflowReducer(builder: ActionReducerMapBuilder<WorkflowsState>) {
  builder.addCase(setWorkflow, (state, action: PayloadAction<Workflow | null>) => {
    state.workflow = action.payload;
  });
}

export function getAllWorkflowsReducer(builder: ActionReducerMapBuilder<WorkflowsState>) {
  builder.addCase(getAllWorkflows.fulfilled, (state, action: PayloadAction<WorkflowListItem[]>) => {
    state.workflows = action.payload;
    state.isWorkflowsLoaded = true;
  });

  builder.addCase(getAllWorkflows.rejected, state => {
    state.workflows = null;
    state.isWorkflowsLoaded = true;
  });
}

export function setIsWorkflowsLoadedReducer(builder: ActionReducerMapBuilder<WorkflowsState>) {
  builder.addCase(setIsWorkflowsLoaded, (state, action: PayloadAction<boolean | null>) => {
    state.isWorkflowsLoaded = action.payload;
  });
}
