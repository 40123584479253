import { FC } from "react";

type Props = { className?: string; width?: string; height?: string; color?: string };

const SocialIcon: FC<Props> = ({ className, width = "22", height = "22", color = "#0484E6" }) => {
  return (
    <svg
      width={width}
      height={height}
      color={color}
      viewBox="0 0 14 14"
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path d="M5.37486 12.8332C5.0811 12.8331 5.76757 12.5999 6.32781 12.1699C6.88805 11.7399 7.29075 11.1371 7.47346 10.4549C7.65617 9.77266 7.60868 9.04923 7.33835 8.39678C7.06802 7.74432 6.58995 7.19929 5.9783 6.84622C5.36665 6.49315 4.65559 6.35176 3.9554 6.44398C3.2552 6.5362 2.605 6.85689 2.10563 7.3563C1.60626 7.8557 1.28563 8.50593 1.19346 9.20613C1.10129 9.90633 1.24273 10.6174 1.59586 11.229L1.32694 12.6728L2.77069 12.4044C3.25852 12.6856 3.81177 12.8335 4.37486 12.8332Z" stroke="#0484E6" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.91393 10.4405C9.47229 10.3499 10.0095 10.1586 10.4994 9.87586L12.5994 10.2667L12.2086 8.16669C12.5518 7.57138 12.7592 6.90765 12.8162 6.22288C12.8731 5.53812 12.7782 4.84923 12.5381 4.20541C12.2979 3.5616 11.9186 2.97875 11.4272 2.49851C10.9358 2.01826 10.3443 1.65246 9.69517 1.42724C9.046 1.20201 8.35511 1.12293 7.67184 1.19563C6.98856 1.26832 6.32978 1.49101 5.74253 1.84778C5.15528 2.20456 4.65407 2.68661 4.27469 3.25951C3.8953 3.83241 3.64712 4.48202 3.54785 5.16194" stroke="#0484E6" strokeLinecap="round" strokeLinejoin="round"/>

    </svg>
  );
};

export default SocialIcon;
