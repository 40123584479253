import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AgentItem } from "api/agent/type";

import { getAgentsListReducer } from "storage/reducers/agent";

interface PaginationMetadata {
  page: number;
  totalPages: number;
  totalItems: number;
}
export interface AgentState {
  agent: AgentItem | null;
  agents: AgentItem[];

  pagination: PaginationMetadata;
}

const initialState: AgentState = {
  agent: null,
  agents: [],

  pagination: {
    page: 1,
    totalPages: 0,
    totalItems: 0,
  },
};

export const agentSlice = createSlice({
  name: "agent",
  initialState,
  reducers: {
    setAgent: (state, action: PayloadAction<AgentItem | null>) => {
      state.agent = action.payload;
    },
  },
  extraReducers: builder => {
    getAgentsListReducer(builder);
  },
});

export const { setAgent } = agentSlice.actions;
export const agentReducer = agentSlice.reducer;
