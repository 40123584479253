import React from "react";
import ReactDOM from "react-dom";

import Button from "shared/components/auth-buttons/Button";

import { ReactComponent as CloseWhiteBtn } from "assets/icons/cross-rounded-white.svg";
import { ReactComponent as ReviewOverlayIcon2 } from "assets/icons/Logo.svg";
interface OverlayProps {
  onClick: () => void;
  onCancel: () => void;
  title: string;
  description: string;
  loading?: boolean;
}

const ConfirmOverlay: React.FC<OverlayProps> = ({ onClick, onCancel, title, description, loading }) => {
  return ReactDOM.createPortal(
    <div id='overlay-general'>
      <div className='flex flex-col items-center justify-center bg-white px-20 py-6 rounded-3xl relative'>
        <CloseWhiteBtn height={36} width={36} className='absolute top-6 right-6' onClick={onCancel} />

        <div className=''>
          <p className='text-3xl font-semibold text-[#1F2228]'>{title}</p>
        </div>
        <div className='mt-6 max-w-486 text-center'>
          <p className='text-base font-medium text-[#5E636E] leading-7'>{description}</p>
        </div>
        <div className='flex flex-row items-center justify-between mt-6 gap-x-4'>
          <Button classes='!w-fit px-[52px] bg-white border border-[#D5D1F7] text-[#1F2228] hover:bg-grey-400' onClick={onCancel}>
            <p>Cancel</p>
          </Button>
          <Button classes='!w-fit px-[52px]' disabled={loading} onClick={onClick}>
            {loading ? <p>Deleting...</p>:<p>Continue</p>}
          </Button>
        </div>
      </div>
    </div>,
    document.body, // Render in the document body to cover the entire viewport
  );
};

export default ConfirmOverlay;
