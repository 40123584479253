import cn from "classnames";
import React from "react";

import { useAppSelector } from "hooks/appHooks";

import LoadingOverlay from "pages/budget/BudgetIntelligence/components/LoadingOverlay";

import classes from "./BudgetBreakdown.module.scss";

const BudgetBreakdown = () => {
  const {loading} = useAppSelector(state => state.project)

//   const loading = false; // Temp - TODO: Connnect it with a real state

  const tempData = [
    {
      name: "Program Spend",
      percentage: "44",
      values: {},
    },
  ];
  return (
    <div className='h-full flex flex-col'>
      <div className={classes.budgets__top}>
        <h2 className={classes.budgets__top__title}>Budget Breakdown</h2>
      </div>
      <div className={cn("bg-white rounded-[19px] px-4 py-3 relative flex-1 flex flex-col", { "overflow-hidden rounded-3xl": loading })}>
        <LoadingOverlay isShown={loading} />
        <div className="flex flex-col h-full gap-6">
          <div>
            <p className='text-sm font-medium text-[#717684]'>Total Budget</p>
            <p className='text-[28px] font-semibold leading-9 text-[#1F2228] mt-1'>$2,020,100</p>
          </div>
          <div className="flex gap-[22px] flex-wrap">
            <div className="flex items-center"> <div className="h-3 w-3 mr-3 rounded-full bg-[#FEB867]"/> Paid Ads</div>
            <div className="flex items-center"> <div className="h-3 w-3 mr-3 rounded-full bg-[#FED649]"/> Influencer</div>
            <div className="flex items-center"> <div className="h-3 w-3 mr-3 rounded-full bg-[#83E28D]"/> Email</div>
            <div className="flex items-center"> <div className="h-3 w-3 mr-3 rounded-full bg-[#70C1FF]"/> Social Media</div>
            <div className="flex items-center"> <div className="h-3 w-3 mr-3 rounded-full bg-[#5C8DFF]"/> Content</div>
            <div className="flex items-center"> <div className="h-3 w-3 mr-3 rounded-full bg-[#CFB5D5]"/> SEM</div>
            <div className="flex items-center"> <div className="h-3 w-3 mr-3 rounded-full bg-[#EDE2FF]"/> SMS</div>
          </div>
          <div className='flex gap-2 w-full justify-between items-stretch flex-grow mt-4'>
            <div className='bg-grey-100 w-full flex flex-col justify-end gap-[2px]'>
              <div style={{height:"5%"}} className="flex justify-center items-center">44%</div>
              <div style={{height:"3%"}} className="bg-[#EDE2FF] rounded-md opacity-95 hover:opacity-100" title="SMS"></div>
              <div style={{height:"7%"}} className="bg-[#CFB5D5] rounded-md opacity-95 hover:opacity-100" title="SEM"></div>
              <div style={{height:"7%"}} className="bg-[#5C8DFF] rounded-md opacity-95 hover:opacity-100" title="Social Media"></div>
              <div style={{height:"8%"}} className="bg-[#70C1FF] rounded-md opacity-95 hover:opacity-100" title="Content"></div>
              <div style={{height:"12%"}} className="bg-[#83E28D] rounded-md opacity-95 hover:opacity-100" title="Email"></div>
              <div style={{height:"13%"}} className="bg-[#FED649] rounded-md opacity-95 hover:opacity-100" title="Influencer"></div>
              <div style={{height:"40%"}} className="bg-[#FEB867] rounded-md opacity-95 hover:opacity-100" title="Paid Ads"></div>
              <div style={{height:"10%"}} className="flex justify-center items-center text-sm text-[#717684]">Program Spend</div>
            </div>

            <div className="bg-grey-100 w-full flex flex-col justify-end gap-[2px]">
            <div style={{height:"5%"}} className="flex justify-center items-center">28%</div>
            <div style={{height:"55%"}} className="bg-[#EDE2FF] rounded-md opacity-95 hover:opacity-100" title="SMS"></div>
            <div style={{height:"10%"}} className="flex justify-center items-center text-sm text-[#717684]">Tooling</div>
            </div>

            <div className="bg-grey-100 w-full flex flex-col justify-end gap-[2px]">
            <div style={{height:"5%"}} className="flex justify-center items-center">15%</div>
            <div style={{height:"35%"}} className="bg-[#EDE2FF] rounded-md opacity-95 hover:opacity-100" title="SMS"></div>
            <div style={{height:"10%"}} className="flex justify-center items-center text-sm text-[#717684]">Other/Miscellaneous</div>
            </div>

            <div className="bg-grey-100 w-full flex flex-col justify-end gap-[2px]">
            <div style={{height:"5%"}} className="flex justify-center items-center">13%</div>
            <div style={{height:"32%"}} className="bg-[#EDE2FF] rounded-md opacity-95 hover:opacity-100" title="SMS"></div>
            <div style={{height:"10%"}} className="flex justify-center items-center text-sm text-[#717684]">Hiring/People Spend</div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BudgetBreakdown;