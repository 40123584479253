import cn from "classnames";
import React, { FC, forwardRef, InputHTMLAttributes, SVGProps, useState } from "react";
import { twMerge } from "tailwind-merge";

import { INPUT_SHARED_CLASSNAME } from "constants/shared/fom-elements.constants";
import { InputsOptions, InputVariants } from "constants/shared/input.constants";

import { SharedFormElementsProps } from "interfaces/shared/form-elements.interfaces";

import { ReactComponent as EyeSlashIcon } from "assets/icons/eye-slash.svg";
import { ReactComponent as EyeIcon } from "assets/icons/InputEye.svg";

import { ErrorMessage } from "./ErrorMessage";
import { FormLabel } from "./FormLabel";

export interface Props extends InputHTMLAttributes<HTMLInputElement>, SharedFormElementsProps {
  icon?: FC<SVGProps<SVGSVGElement>>;
  onIconClick?: VoidFunction;

  variant?: InputVariants;
  containerClassName?: string;
}

// TODO - create Input variants(for styles)
export const Input = forwardRef<HTMLInputElement, Props>(
  (
    { className, onChange, containerClassName, variant = InputVariants.LARGE, onIconClick, error, type, label, icon: Icon, ...props },
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    return (
      <div className={cn("w-full", containerClassName)}>
        {label && <FormLabel>{label}</FormLabel>}
        <div className='relative'>
          {Icon && (
            <Icon
              onClick={onIconClick}
              className={twMerge("absolute left-3.5 text-[#322D60]/70 cursor-pointer", InputsOptions[variant]?.iconClassName)}
            />
          )}
          <input
            type={showPassword ? "text" : type}
            ref={ref}
            className={twMerge(
              cn("w-full", INPUT_SHARED_CLASSNAME, InputsOptions[variant]?.className, {
                "pl-10": Icon,
                "border-[#E36C74]": error?.message,
              }),
              className,
            )}
            onChange={onChange}
            {...props}
          />
          {type === "password" && (
            <button type='button' className='absolute right-4 top-[calc(50%-9px)] cursor-pointer' onClick={togglePasswordVisibility}>
              {showPassword ? (
                <EyeSlashIcon className={InputsOptions[variant]?.iconClassName} />
              ) : (
                <EyeIcon className={InputsOptions[variant]?.iconClassName} />
              )}
            </button>
          )}
        </div>
        <ErrorMessage error={error?.message} />
      </div>
    );
  },
);

Input.displayName = "Input";
