import { cn } from "lib/utils";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  classes?: string;
  className?: string;
}

export default function Button({ classes, className, children, ...props }: ButtonProps) {
  return (
    <button
      {...props}
      className={cn(
        "rounded-[16px] h-[40px] py-3 px-3 w-full inline-flex justify-center items-center gap-x-2 text-base text-white font-semibold bg-[#6A5EEE] hover:bg-[#6B62CF] active:border active:border-[#544DA2] focus:outline-none focus:ring-2 focus:ring-[#C0BBF3] disabled:text-[#C8CAD0] disabled:bg-[#E9EAEC]",
        classes,
        className,
      )}
    >
      {children}
    </button>
  );
}
