import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { ILoginData } from "api/auth/types";

import { ROUTES } from "constants/routes.constants";
import { ButtonVariants } from "constants/shared/button.constants";
import { TypographyVariants } from "constants/shared/typography.constants";

import { useAppDispatch } from "hooks/appHooks";
import { useGoogleLoginRequest } from "hooks/useGoogleLogin";

import { loginUser } from "storage/actions/auth";
import { getMeData } from "storage/actions/user";

import GoogleButton from "shared/components/auth-buttons/GoogleButton";
import { Button } from "shared/components/buttons/Button";
import { LinkButton } from "shared/components/buttons/LinkButton";
import { Input } from "shared/components/form-elements/Input";
import { Typography } from "shared/components/Typography";
import { AuthLayout } from "shared/layouts/AuthLayout";

import { ReactComponent as LockIcon } from "assets/icons/lock.svg";
import { ReactComponent as ProfileIcon } from "assets/icons/profile.svg";
import { ReactComponent as EmailIcon } from "assets/icons/sms.svg";

const schema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .matches(/^[a-zA-Z0-9._%+-]+@[a-z]+\.[a-z]{2,}$/, "Email is not valid")
    .email("Email is not valid"),

  password: yup.string().required("Password is required"),
});

export default function SignIn() {
  const dispatch = useAppDispatch();

  const { googleLogin } = useGoogleLoginRequest();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginData>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const onSubmit = async (data: ILoginData) => {
    dispatch(loginUser(data))
      .unwrap()
      .then(() => {
        dispatch(getMeData("login"));
      });
  };

  return (
    <AuthLayout>
      <div className='grow px-5 min-h-screen flex flex-col justify-center py-6'>
        <div className='h-fit sm:w-[448px] flex flex-col  mx-auto '>
          <div className='mb-16'>
            <div className='flex justify-center'>
              <ProfileIcon />
            </div>
            <Typography className='my-4 text-center' variant={TypographyVariants.H1}>
              Log in <span className='text-[#9A91A8]'>to your account</span>
            </Typography>
            {/*<Typography variant={TypographyVariants.LG_MEDIUM} className='text-center text-[#9A91A8]'>
              Welcome back!  Don’t have an account yet?  Sign up now.
            </Typography>*/}
          </div>

          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <div className='mb-4'>
              <div className='space-y-4 mb-6'>
                <Input {...register("email", { required: true })} type='email' icon={EmailIcon} error={errors?.email} placeholder='Email' />
                <Input
                  {...register("password", { required: true })}
                  type='password'
                  icon={LockIcon}
                  error={errors?.password}
                  placeholder='Password'
                />
              </div>
              <div className='flex justify-end items-center'>
                <LinkButton to={ROUTES.resetPassword} variant={ButtonVariants.LINK_TEXT_MEDIUM}>
                  Forgot password?
                </LinkButton>
              </div>

              <Button className='mt-8'>Sign in</Button>
            </div>
          </form>

          <div className='mb-6'>
            <GoogleButton onClick={() => googleLogin()}>Sign in with Google</GoogleButton>
          </div>

          {/*<Typography variant={TypographyVariants.MD_MEDIUM} className='flex justify-center items-center'>
            Don't have an account yet?{" "}
            <LinkButton to={ROUTES.signUp} variant={ButtonVariants.LINK_TEXT_MEDIUM} className='ml-2'>
              Sign up
            </LinkButton>
          </Typography>*/}
        </div>
      </div>
    </AuthLayout>
  );
}
