import { FallbackProps } from "react-error-boundary";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "constants/routes.constants";

import { Button } from "shared/components/buttons/Button";

import { ReactComponent as BrainvoyIcon } from "assets/icons/Logo.svg";

const  ErrorBoundaryComponent = ({ error, resetErrorBoundary }:FallbackProps) => {
    const navigate = useNavigate();
    return (
        <div className='h-screen flex flex-col items-center justify-center'>
        <div className='flex flex-col items-center'>
          <p className='text-5xl font-bold text-purple'>Brainvoy</p>
          <BrainvoyIcon className='w-56 h-56 mt-6' />
          <p className='text-lg font-bol mt-6 text-red-800'>Error: {error.message}</p>
          <Button
            className='mt-6'
            onClick={() => {
              navigate(ROUTES.dashboard);
              resetErrorBoundary()
            }}
          >
            <p>Go Home</p>
          </Button>
        </div>
      </div>
    );
  }


  export default ErrorBoundaryComponent;