import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { useChannelStrategy } from "hooks/channels/useChannelStrategy";

import { getOneProject } from "storage/actions/project";
import { getWorkflowsStrategiesList } from "storage/actions/workflows";
import { setLoading } from "storage/slices/loader-slice";
import { getUserPreferences } from "storage/slices/preferences-slice";

import { DashboardLayout } from "shared/layouts/DashboardLayout";

import ChannelStrategy from "../budget/BudgetIntelligence/MarketingpView/ChannelStrategy/ChannelStrategy";

export const WorkflowDetails = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams();

  const { state } = useLocation();

  const navigate = useNavigate();
  const project = useAppSelector(state => state.project.project);

  const [transformedData] = useChannelStrategy({ budgetData: project?.project_data?.budget_data });

  useEffect(() => {
    dispatch(getWorkflowsStrategiesList())
    dispatch(getUserPreferences());
    if (!id || project?.project_id === Number(id)) return;
    dispatch(getOneProject(Number(id), (res:string)=>{
        if(res === "error"){
          navigate(-1)
        }
    }));
  }, [dispatch, id, project?.project_id]);

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getWorkflowsStrategiesList());

    setTimeout(() => dispatch(setLoading(false)), 800);
  });

  return (
    <DashboardLayout title='Workflow'>
      <div className='p-2'>
        <ChannelStrategy data={transformedData} activeDate={state?.activeDate} className='min-h-[calc(100vh-7rem)]' />
      </div>
    </DashboardLayout>
  );
};
