import cn from "classnames";
import { useContext, useEffect, useRef, useState } from "react";

import { ReactComponent as TickIcon } from "assets/icons/check-white.svg";
import { ReactComponent as CrossIcon } from "assets/icons/cross-black.svg";
import { ReactComponent as CompanyProfileIcon } from "assets/icons/editIcon.svg";
import { ReactComponent as NoteIcon } from "assets/icons/note.svg";

import { SetupCompanyProfileContext } from "../SetupCompanyProfile";

import { DescriptionLoader } from "./loadingComponents";

const CompanyDescription = () => {
  const [text, setText] = useState<string>("");
  const [editable, setEditable] = useState(false);
  const {data, loading, apiData, updateApiData } = useContext(SetupCompanyProfileContext);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const handleTick = () => {
    updateApiData({ ...apiData, description: text });
    setEditable(false);
  };
  const handleCrossClick = () => {
    setText(apiData.description);
    setEditable(false);
  };
  useEffect(() => {
    if (editable) {
      const len = text.length;
      textAreaRef.current?.setSelectionRange(len, len);
      textAreaRef.current?.focus();
    }
  }, [editable, text]);
  useEffect(() => {
    if (apiData.description) {
      setText(apiData.description);
    }
  }, [apiData.description]);

  return (
    <div className='p-6 bg-white rounded-2xl'>
      <div className='flex gap-x-4'>
        <div>
          <NoteIcon />
        </div>
        <div className='w-full'>
          <div className='flex justify-between'>
            <p className='text-base font-bold text-[#2E323A]'>Company description</p>
            <div className='flex items-center gap-x-2'>
              {!editable ? (
                <button
                  className={cn("p-[7px] rounded-full border border-[#E9EAEC]",{"opacity-30":!data})}
                  disabled={!data}
                  onClick={() => {
                    setEditable(true);
                  }}
                >
                  <CompanyProfileIcon className='h-[18px] w-[18px]' />
                </button>
              ) : (
                <>
                  <button className='p-[7px] rounded-full border border-[#E9EAEC]' onClick={handleCrossClick}>
                    <CrossIcon className='h-[18px] w-[18px]' />
                  </button>
                  <button className='p-[7px] rounded-full border border-[#E9EAEC] bg-purple' onClick={handleTick}>
                    <TickIcon className='h-[18px] w-[18px]' />
                  </button>
                </>
              )}
            </div>
          </div>
          {!loading ? (
            <div className='mt-2'>
              {editable ? (
                <div className='p-2  rounded-xl shadow-thickPurple border border-purple'>
                  <textarea
                    onChange={e => setText(e.target.value)}
                    rows={4}
                    value={text}
                    className='w-full focus:outline-none'
                    ref={textAreaRef}
                  />
                </div>
              ) : (
                <p className='leading-[1.7] text-base font-medium text-[#5E636E]'>{text}</p>
              )}
            </div>
          ) : (
            <div className='mt-2'>
              <DescriptionLoader />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default CompanyDescription;
