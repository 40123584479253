import { useEffect, useState } from "react";
import io, { Socket } from "socket.io-client";

type UseSocketIOHook = {
  socket: Socket | null;
};

export const useSocketIO = (url: string): UseSocketIOHook => {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    // Initialize the socket connection
    const token = localStorage.getItem("accessToken")
    const newSocket = io(url, {
      auth: {token},
      transports: ["websocket"],
    });

    // Log connection and disconnection events
    newSocket.on("connect", () => {
      console.log("Connected to socket.io server");
    });

    newSocket.on("disconnect", () => {
      console.log("Disconnected from socket.io server");
    });

    newSocket.on("connect_error", (error) => {
      console.error("Connection error:", error.message);
    });

    newSocket.on("reconnect_attempt", (attempt) => {
      console.log(`Reconnecting attempt #${attempt}`);
    });

    // Save the socket instance
    setSocket(newSocket);

    return () => {
      // Cleanup listeners and disconnect on unmount
      alert("Disconnect")
      newSocket.removeAllListeners();
      newSocket.disconnect();
    };
  }, [url]);



 

  return { socket };
};