import { hasGrantedAllScopesGoogle, TokenResponse, useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router";

import { createGoogleCredentials } from "api/budget";

import { ROUTES } from "constants/routes.constants";

import { googleAuth } from "storage/actions/auth";
import { setSocialSignup } from "storage/slices/upload-budget-slice";

import Alert from "shared/components/toasts";

import { useAppDispatch } from "./appHooks";

export const useGoogleLoginRequest = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse: { access_token: string }) => {
      dispatch(googleAuth({ token: codeResponse.access_token }))
        .unwrap()
        .then(res => {
          if (res?.accessToken) {
            navigate(ROUTES.dashboard);
          }
        });
    },
    onError: error => console.error("Login Failed:", error),
  });

  return { googleLogin };
};

const GoogleGenericRequiredScopes: string[] = [
  "email",
  "openid",
]

const GoogleSheetsRequiredScopes: string[] = [
  "https://www.googleapis.com/auth/spreadsheets.readonly",
  "https://www.googleapis.com/auth/drive.readonly",
]

const requiredScopesForService = (service: string) => {
  console.log(service)
  switch(service) {
    
    case "Google Sheets": {
      return GoogleSheetsRequiredScopes;
    }
    case "Excel file":{
      return []
    }
    case "CSV file":{
      return []
    }
    default: {
      throw new Error(`Service ${service} not supported`);
    }
  } 
};

const hasGrantedAllScopesForService = (service: string, tokens: TokenResponse) => {
  const requiredScopes = requiredScopesForService(service);
  const firstScope = requiredScopes.shift() || "";
  switch(service) {
    case "Google Sheets": {
      return hasGrantedAllScopesGoogle(
        tokens,
        firstScope,
        ...requiredScopes,
      );
    }
    default: {
      throw new Error(`Service ${service} not supported`);
    }
  } 
}

export const useGoogleOIDC = (service: string) => {
  const dispatch = useAppDispatch();
  const requiredScopes = requiredScopesForService(service);
  const allScopes = requiredScopes.concat(...GoogleGenericRequiredScopes);
  const handleGoogleLogin = useGoogleLogin({
    flow: "auth-code",
    overrideScope: true,
    scope: allScopes.join(" "),
    onSuccess: async response => {
      try{
        if(service === "Excel" || service ==="CSV") return
        console.log(response, "response in google api");
        const tokens: TokenResponse = await createGoogleCredentials({
          code: response.code,
          label: service,
        })
        console.log("tokens: %o", tokens);
        const hasAccess = hasGrantedAllScopesForService(service, tokens);
        if (hasAccess) {
          dispatch(setSocialSignup({ social: "googleAccessToken", token: tokens?.access_token }));
        } else {
          throw new Error("Google Sheets access not granted");
        }
      }catch(err){
        if(err instanceof Error){
          Alert("error", err?.message, "Error");
        }
      }
     
    },
    onError: error => console.error("Login Failed:", error),
  });
  return handleGoogleLogin;
};
