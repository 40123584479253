import React, { useEffect, useRef } from "react";
import { twMerge } from "tailwind-merge";

import { IChannelData } from "api/budget/types";

import { formatNumberToUSLocale } from "utils/shared.utils";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";

import { handleProjectForm } from "storage/slices/project-slice";

import DatePicker from "shared/components/budget/datePicker/DatePicker";
import GoalItem from "shared/components/budget/goalItem/GoalItem";

import { ReactComponent as DashIcon } from "assets/icons/dashIcon.svg";

import { ButtonVariants } from "../../../constants/shared/button.constants";
import { INPUT_SHARED_CLASSNAME } from "../../../constants/shared/fom-elements.constants";
import { TypographyVariants } from "../../../constants/shared/typography.constants";
import { Button } from "../../../shared/components/buttons/Button";
import { FormLabel } from "../../../shared/components/form-elements/FormLabel";
import { Typography } from "../../../shared/components/Typography";

interface IMarketingProps {
  nextStepHandler: () => void;
}

export default function MarketingStrategyForm({ nextStepHandler }: IMarketingProps) {
  const dispatch = useAppDispatch();
  const formData = useAppSelector(state => state.project.newProjectForm);

  const [channelItem, setChannelItem] = React.useState<IChannelData[]>(formData.channels || []);
  const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);

  const projectChannels = useAppSelector(state => state.channels.channels);
  const selectableProjectChannels = projectChannels.filter(channel => channel.channel_id !== 0 && channel.name !== "Other");

  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const selectChannelHandler = (channel: any) => {
    if ("channel_id" in channel) {
      setChannelItem(prev => {
        if (prev.find(item => item.name === channel.name)) {
          const newChannels = prev.filter(item => item.name !== channel.name);
          dispatch(handleProjectForm({ ...formData, channels: [...newChannels] }));
          return newChannels;
        }
        dispatch(handleProjectForm({ ...formData, channels: [...prev, channel] }));
        return [...prev, channel];
      });
    }
  };

  const selectBudgetHandler = (value: string | null, key: string) => {
    const numericValue = Number(value?.toString().replace(/,/g, ""));
    if (!isNaN(numericValue)) {
      dispatch(handleProjectForm({ ...formData, [key]: numericValue }));
    }
  };

  const handleAddChannel = (addedChannel: any) => {
    const channelExists = channelItem.some(channel => channel.name === addedChannel?.name);
    let updatedChannels;
    if (channelExists) {
      updatedChannels = channelItem.filter(channel => channel.name !== addedChannel?.name);
    } else {
      updatedChannels = [...channelItem, addedChannel];
    }
    setChannelItem(updatedChannels);

    if (Array.isArray(formData.channels)) {
      const updatedFormDataChannels = channelExists
        ? formData.channels.filter(channel => channel.name !== addedChannel?.name)
        : [...formData.channels, addedChannel];

      dispatch(handleProjectForm({ ...formData, channels: updatedFormDataChannels }));
    } else {
      dispatch(handleProjectForm({ ...formData, channels: [...updatedChannels] }));
    }
  };

  const combinedChannels = [...selectableProjectChannels, ...channelItem];

  const uniqueChannels = combinedChannels.filter((item, index, self) => index === self.findIndex(t => t.name === item.name));

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className='rounded-xl flex-1 flex flex-col justify-between h-full w-full gap-y-6'>
      <Typography variant={TypographyVariants.H2}>
        Marketing strategy <span className='text-[#766CE4]'>for the year</span>
      </Typography>
      <div className='bg-white py-9 px-8 border-[1.5px] border-[#EFF0F2] rounded-[22px] flex flex-col w-full'>
        <div id='addMoreId' className='text-[18px] font-bold flex items-center justify-start pb-2 text-[#1F2228]'>
          Marketing channels to include *
        </div>
        <p className='text-[13px] font-medium text-[#717684] pb-8'>Select all that applies</p>
        <div className='flex flex-col w-full relative gap-4'>
          <div className='flex items-center gap-4 flex-wrap'>
            {uniqueChannels.map((item, index) => (
              <GoalItem key={index} checked={!!channelItem.find(el => el.name === item.name)} onChecked={() => handleAddChannel(item)}>
                {item.name}
              </GoalItem>
            ))}
          </div>
        </div>
      </div>
      <div className='bg-white border-[1.5px] border-[#EFF0F2] rounded-[22px] flex-1 p-9 flex flex-col h-full w-full'>
        <div className='flex items-start w-full font-bold text-base'>Estimated marketing budget for the year *</div>
        <div className='flex pt-8 items-center w-full h-full gap-x-4'>
          <div>
            <FormLabel>From</FormLabel>
            <div className='relative max-w-[190px] flex items-center text-[#1F2228] font-medium text-sm'>
              <span className='flex items-center pointer-events-none w-[18px] h-[18px] absolute top-1/2 transform -translate-y-1/2 left-3.5 text-[#9EA2AD]'>
                $
              </span>
              <input
                value={formData.budget_from ? formatNumberToUSLocale(formData.budget_from) : ""}
                onChange={e => selectBudgetHandler(e.target.value, "budget_from")}
                placeholder='0'
                type='text'
                className={twMerge("w-full py-[9px] pr-[55px] pl-6 block", INPUT_SHARED_CLASSNAME)}
              />
              <span className='cursor-pointer w-6 h-[18px] absolute top-1/2 transform -translate-y-1/2 right-6 text-[#333740]'>USD</span>
            </div>
          </div>
          <DashIcon className='hidden 2xl:block' />
          <div>
            <FormLabel>From</FormLabel>
            <div className='relative max-w-[190px] flex items-center text-[#1F2228] font-medium text-sm'>
              <span className='flex items-center pointer-events-none w-[18px] h-[18px] absolute top-1/2 transform -translate-y-1/2 left-3.5 text-[#9EA2AD]'>
                $
              </span>
              <input
                value={formData.budget_to ? formatNumberToUSLocale(formData.budget_to) : ""}
                onChange={e => selectBudgetHandler(e.target.value, "budget_to")}
                placeholder='0'
                type='text'
                className={twMerge("w-full py-[9px] pr-[55px] pl-6 block", INPUT_SHARED_CLASSNAME)}
              />
              <span className='cursor-pointer w-6 h-[18px] absolute top-1/2 transform -translate-y-1/2 right-6 text-[#333740]'>USD</span>
            </div>
          </div>

          <DatePicker />
        </div>
      </div>

      <div className='flex h-full items-end justify-end w-full mt-auto 1xl:m-auto 1xl:mb-[140px]'>
        <Button
          className='w-fit'
          variant={ButtonVariants.SMALL}
          disabled={
            !(
              formData.budget_from &&
              formData.budget_to &&
              formData.budget_date_from &&
              formData.budget_date_to &&
              formData?.channels?.length
            )
          }
          onClick={nextStepHandler}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
