import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router";

import { IChannelData } from "api/budget/types";
import { createProject, initializeProject, updateProject } from "api/projects";

import { INPUT_SHARED_CLASSNAME } from "constants/shared/fom-elements.constants";
import { REQUIRED_AND_ONLY_ENGLISH_FIELD_VALIDATION, REQUIRED_URL_FIELD_VALIDATION } from "constants/validation.constants";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { useDebouncedEffect } from "hooks/useDebouncedEffect";
import { useString } from "hooks/useString";

import { getOneProject, getProjectsList } from "storage/actions/project";
import { setLoading } from "storage/slices/loader-slice";
import { openPopup } from "storage/slices/popup-slice";
import { handleProjectForm, setPoject, setUploadedPoject } from "storage/slices/project-slice";
import { setIsCollapsed } from "storage/slices/sidebar-slice";

import { useAI } from "shared/components/ai-panel/provider";
import Button from "shared/components/auth-buttons/Button";
import { Breadcrumbs } from "shared/components/breadcrumbs/Breadcrumbs";
import DatePicker from "shared/components/budget/datePicker/DatePicker";
import GoalItem from "shared/components/budget/goalItem/GoalItem";
import ItemInput from "shared/components/budget/goalItem/ItemInput";
import { FormLabel } from "shared/components/form-elements/FormLabel";
import { Input } from "shared/components/form-elements/Input";
import { Icons } from "shared/components/icons";
import Alert from "shared/components/toasts";
import { DashboardLayout } from "shared/layouts/DashboardLayout";

import { ReactComponent as EditIcon } from "assets/icons/editIcon.svg";

import { cn } from "lib/utils";

import DescribeProjectForm from "./forms/DescribeProjectForm";
import GoalsProjectForm from "./forms/GoalsProjectForm";
import MarketingStrategyForm from "./forms/MarketingStrategyForm";
import { stepFormConfig } from "./stepFormConfig";

export default function NewBudgetForm() {
  return (
    <DashboardLayout title='Start Your Project' containerClassName='bg-white' isScrollable={false}>
      <BudgetForm />
    </DashboardLayout>
  );
}

const BudgetForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const data: any = useAppSelector(state => state.project);
  const file_id: number | null = useAppSelector(state => state.project?.uploadedProject?.file_id) || null;
  const formData = useAppSelector(state => state.project.newProjectForm);
  const { state } = useLocation();
  const marketingChannels = useAppSelector(state => state.channels.channels);
  const { id } = useParams();
  const [currentStep, setCurrentStep] = React.useState(1);
  const [project_id, setProjectId] = useState<string | undefined>(id);

  const form = useForm({ mode: "onChange" });

  const isNextBtnDisabled = useMemo(() => {
    if (currentStep === 1) {
      const isNameValid = formData.name?.trim() !== "";

      const isSelectedGoalsLesser = !formData.goals || formData.goals.length < 3;

      return !isNameValid || isSelectedGoalsLesser;
    }

    return false;
  }, [formData, currentStep]);

  function validateForm(description: any, name: any): boolean {
    // const isDescriptionValid = description?.length >= 120;
    const isNameValid = name?.trim() !== "";
    // return isDescriptionValid && isNameValid;
    return isNameValid;
  }

  const updateProjectData = useCallback(
    (apiData: any) => {
      if (currentStep === 3) {
        dispatch(setLoading(true));
      }
      updateProject(apiData, project_id)
        .then(async res => {
          await dispatch(getOneProject(Number(project_id)));
          navigate(`/budget/${project_id}/budget-intelligence`);
          dispatch(getProjectsList({ page: 1 }));
        })
        .catch(err => {
          Alert("error", err.message, "Error");
        })
        .finally(() => {
          dispatch(setLoading(false));
        });
    },
    [currentStep, project_id, dispatch, navigate],
  );

  const nextStepHandler = useCallback(async () => {
    const { revenue_from, revenue_to, isRevenueUnknown, ...formValue } = data?.newProjectForm || {};

    if (currentStep === 3) {
      const apiData = {
        name: formValue?.name,
        description: formValue?.description,
        audience: formValue?.audience,
        goals: formValue?.goals,
        revenue_from: revenue_from,
        revenue_to: revenue_to,
        revenue_rate: formValue?.revenue_rate,
        isRevenueUnknown: isRevenueUnknown,
        channels: formValue?.channels.map((item: IChannelData) => item.channel_id),
        budget_from: formValue?.budget_from,
        budget_to: formValue?.budget_to,
        budget_date_from: formValue?.budget_date_from,
        budget_date_to: formValue?.budget_date_to,
        ...(file_id && { file_id: file_id }),
      };
      updateProjectData(apiData);
    } else {
      if (currentStep == 1) {
        const isValidatedCorrectly = await form.trigger();
        if (!isValidatedCorrectly) return;

        if (!validateForm(formValue?.description, formValue?.name)) {
          return;
        }
      }

      setCurrentStep(prev => prev + 1);
    }
  }, [currentStep, data?.newProjectForm, updateProjectData]);

  // useEffect(() => {
  //   console.log("All the Biggest")
  //   if (data?.uploadedProject && data.method !== "manual") {
  //     console.log("inside manual")
  //     dispatch(
  //       handleProjectForm({
  //         ...data.newProjectForm,
  //         budget_from: data?.uploadedProject?.budget_from,
  //         budget_date_to: data?.uploadedProject?.budget_date_to,
  //         budget_date_from: data?.uploadedProject?.budget_date_from,
  //         budget_to: data?.uploadedProject?.budget_to,
  //         revenue_from: data?.uploadedProject?.revenue_from,
  //         revenue_to: data?.uploadedProject?.revenue_to,
  //         revenue_rate: data?.uploadedProject?.revenue_rate,
  //         goals: [...(data?.newProjectForm?.goals || [])],
  //         channels: [...marketingChannels.filter(item => data?.uploadedProject?.channels?.find((goal: any) => goal === item.channel_id))],
  //       }),
  //     );
  //   }
  // }, [data.method, data.newProjectForm, data?.uploadedProject, dispatch, marketingChannels]);
  const createNewProject = useCallback(() => {
    initializeProject()
      .then(res => {
        setProjectId(res.project_id);
        dispatch(
          handleProjectForm({
            name: "",
            description: null,
            audience: [],
            goals: [],
            budget_from: null,
            budget_to: null,
            budget_date_from: null,
            budget_date_to: null,
            revenue_from: null,
            revenue_to: null,
            revenue_rate: null,
            channels: [],
          }),
        );
      })
      .catch(err => {
        if (err.message === "Your pricing plan does not allow creating new budgets") {
          dispatch(openPopup("MembershipsModal"));
        } else {
          Alert("error", err.message, "Error");
        }
      });
  }, [dispatch]);
  const prefillProjectData = useCallback(() => {
    console.log("Prefill Project Data");
    dispatch(
      handleProjectForm({
        ...data.project,
      }),
    );
  }, [dispatch, data.project]);
  useEffect(() => {
    dispatch(setIsCollapsed(true));
    return () => {
      dispatch(handleProjectForm({}));
      dispatch(setUploadedPoject(null));
    };
  }, [dispatch]);
  useEffect(() => {
    if (id) {
      prefillProjectData();
    } else {
      createNewProject();
    }
  }, [id, prefillProjectData, createNewProject]);
  const saveFileId = (apiData: any) => {
    updateProject(apiData, project_id)
      .then(res => {
        console.log("Respo", res);
      })
      .catch(res => {
        Alert("error", res.message, "Error");
      });
  };
  useEffect(() => {
    if (state?.fileName && project_id) {
      const apiData = {
        file_id: file_id,
      };
      saveFileId(apiData);
      dispatch(setUploadedPoject(null));
    }
  }, [state?.fileName, project_id]);
  const { isOpen, openPanel } = useAI();

  useEffect(() => {
    if (isOpen == false) {
      openPanel();
    }
  }, []);

  return (
    <div className='flex flex-col h-full p-6'>
      <Breadcrumbs
        breadcrumbs={[
          {
            path: "/",
            label: "Home",
            active: false,
          },
          {
            path: "/projects",
            label: "Browse projects",
            active: false,
          },
          {
            path: "/budget/form",
            label: "Create new budget",
            active: true,
          },
        ]}
      />
      <div className='mt-8 flex flex-col mb-4'>
        <h1 className='text-2xl text-[#333740] font-semibold'>Create new marketing budget</h1>
        <div className='flex mt-4'>
          <div className='flex items-center gap-x-2 pr-4'>
            <StepBtn step={1} title='Company details' currentStep={currentStep} setCurrentStep={setCurrentStep} />
            <StepBtn step={2} title='Select source' currentStep={currentStep} setCurrentStep={setCurrentStep} />
            <StepBtn step={3} title='Customize' currentStep={currentStep} setCurrentStep={setCurrentStep} />
          </div>
        </div>
      </div>
      <div className='flex flex-col justify-between h-full w-full'>
        {currentStep === 1 && <StepOne form={form} />}
        {currentStep === 2 && <StepTwo />}
        {currentStep === 3 && <StepThree />}
        <BtnGroup
          showSkip={currentStep > 1}
          disabledSkipHandler={currentStep == 3 || currentStep == 1}
          disabledPrevHandler={currentStep <= 1}
          disabledNextHandler={isNextBtnDisabled}
          nextStepHandler={nextStepHandler}
        />
      </div>
    </div>
  );
};

const StepBtn = ({
  step,
  title,
  setCurrentStep,
  currentStep,
}: {
  step: number;
  title: string;
  currentStep: number;
  setCurrentStep: (step: number) => void;
}) => {
  const isCurrentStep = step === currentStep;

  return (
    <button
      type='button'
      disabled={currentStep < step}
      onClick={() => (currentStep < step ? null : setCurrentStep(step))}
      className={cn(
        "px-5 py-2.5 rounded-[24px] text-sm font-medium  flex items-center gap-2.5 disabled:cursor-not-allowed",
        isCurrentStep ? "bg-[#F7F7F8]" : "border border-[#E9EAEC] text-[#717684]",
      )}
    >
      <span
        className={cn(
          "border w-5 h-5 rounded-full flex items-center justify-center font-semibold text-xs text-white",
          isCurrentStep ? "bg-[#333740] border-[#333740]" : "bg-[#9EA2AD]  border-[#9EA2AD]",
        )}
      >
        {step}
      </span>
      {title}
    </button>
  );
};

const StepOne = ({ form }: { form: UseFormReturn<any> }) => {
  const staticMetrics = ["Increase Sales", "Increase Traffic", "Lower CAC", "Increase CLTV", "Improve CSAT", "Increase Revenue"];

  const dispatch = useAppDispatch();

  const formData = useAppSelector(state => state.project.newProjectForm);

  const [audienceError, setAudienceError] = useString();

  const watchDescriptionField = form.watch("description");
  const values = form.watch();

  const [goalItem, setGoalItem] = useState<string[]>(formData.goals || []);

  const [isItemInputVisible, setIsItemInputVisible] = useState(false);

  const [growPercentage, setGrowPercentage] = useState<number>(formData.revenue_rate || 0);

  const selectBudgetHandler = (value: string | null, key: string) => {
    const numericValue = Number(value?.toString().replace(/,/g, ""));
    if (!isNaN(numericValue)) {
      dispatch(handleProjectForm({ ...formData, [key]: numericValue }));
    }
  };

  const growPercentageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;
    if (isNaN(value) || value < 0 || value > 100) {
      return;
    } else {
      setGrowPercentage(value);
      dispatch(handleProjectForm({ ...formData, revenue_rate: value }));
    }
  };

  const selectGoalHandler = (goal: any) => {
    setGoalItem(prev => {
      if (prev.find(item => item === goal)) {
        const newGoals = prev.filter(item => item !== goal);
        dispatch(handleProjectForm({ ...formData, goals: [...newGoals] }));
        return newGoals;
      } else if (prev.length < 3) {
        dispatch(handleProjectForm({ ...formData, goals: [...prev, goal] }));
        return [...prev, goal];
      }
      return [...prev];
    });
  };

  const handleAddGoal = (goalName: string) => {
    setGoalItem([...goalItem, goalName]);
    if (Array.isArray(formData.goals)) {
      dispatch(handleProjectForm({ ...formData, goals: [...formData?.goals, goalName] }));
    }
    setIsItemInputVisible(false);
  };

  useEffect(() => {
    if (Object.values(values)?.length) return;
    form.reset(formData);
  }, [formData, form.reset, values]);

  useDebouncedEffect(
    () => {
      if (!Object.values(values)?.length) return;
      dispatch(handleProjectForm({ ...formData, ...values }));
    },
    [dispatch, values?.name, values?.description, values?.company_website_url],
    1000,
  );

  const combinedItems = [...staticMetrics, ...goalItem];
  const uniqueItems = combinedItems.filter((item, index, self) => index === self.findIndex(t => t === item));

  return (
    <div className='flex flex-col'>
      <div className='flex flex-col gap-2'>
        <h3 className='font-bold text-base'>Fill company details</h3>
        <p className='text-[#5E636E] font-medium text-sm max-w-[500px]'>
          Provide some basic information to let our AI agent design a highly optimized and accurate budget for your needs.
        </p>
      </div>
      <div className='flex flex-col gap-3.5 mt-4'>
        <Input
          {...form.register("name", REQUIRED_AND_ONLY_ENGLISH_FIELD_VALIDATION)}
          label='Project name *'
          placeholder='Type your project name here'
          maxLength={50}
          containerClassName='flex flex-col gap-1'
          error={form.formState.errors?.name}
        />
        <Input
          {...form.register("company_website_url", REQUIRED_URL_FIELD_VALIDATION)}
          label='Company website url *'
          placeholder='www.brainvoy.ai'
          maxLength={50}
          containerClassName='flex flex-col gap-1'
          error={form.formState.errors?.company_website_url}
        />
        <div className='flex flex-col gap-1'>
          <FormLabel>
            Goal *<span className='text-[#717684] text-xs ml-1'>Select up to 3 metrics </span>
          </FormLabel>
          <div className='flex w-full flex-wrap gap-1.5'>
            {uniqueItems?.map((itemGoal, index) => (
              <GoalItem
                key={index}
                checked={!!goalItem.find(item => item === itemGoal)}
                onChecked={value => {
                  selectGoalHandler(itemGoal);
                }}
              >
                {itemGoal}
              </GoalItem>
            ))}
            {isItemInputVisible && <ItemInput currentMetrics={uniqueItems} onSubmit={handleAddGoal} />}
            <button
              disabled={Array.isArray(formData.goals) && formData.goals?.length === 3}
              onClick={() => setIsItemInputVisible(true)}
              className={cn(
                "flex gap-2 items-center justify-center border  py-2.5 pl-6 pr-4 rounded-[56px] bg-white border-[#D5D1F7] drop-shadow-sm text-[#766CE4] border-dashed text-sm font-medium disabled:cursor-not-allowed disabled:text-[#D5D1F7] disabled:border-[#D5D1F7]",
              )}
            >
              Add more <Icons.Plus className=' ' />
            </button>
          </div>
        </div>
        <div className='grid grid-cols-5 gap-2 w-full'>
          <div className=' col-span-5 md:col-span-3'>
            <DatePicker className='2xl:!ml-0 !max-w-[80%]' />
          </div>
          <div className='col-span-5 md:col-span-2'>
            <label className='flex flex-col items-start ml-0 2xl:ml-[56px] w-fit gap-y-1'>
              <div className='text-sm text-[#1F2228] font-semibold w-fit pb-1'>Revenue growth rate</div>
              <div className='relative flex flex-shrink-0 items-center text-[#2c1a49] font-medium text-sm gap-x-4'>
                <div
                  onClick={() => {
                    setGrowPercentage(prev => {
                      if (prev === 0) return prev;
                      return prev - 1;
                    });
                    dispatch(
                      handleProjectForm({
                        ...formData,
                        revenue_rate: growPercentage === 0 ? 0 : growPercentage - 1,
                      }),
                    );
                  }}
                  className='flex flex-shrink-0 items-center justify-center rounded-full bg-[#C8C4F4] h-8 w-8 cursor-pointer'
                >
                  <Icons.Minus className='text-[#14191A]' />
                </div>
                <input
                  onChange={growPercentageHandler}
                  value={formData.revenue_rate || growPercentage || ""}
                  placeholder='0'
                  type='number'
                  maxLength={3}
                  className={cn(INPUT_SHARED_CLASSNAME, "py-[9px] px-8 max-w-[100px]")}
                />
                <span className='cursor-pointer flex items-center text-[#9EA2AD] w-6 h-6 absolute top-1/2 transform -translate-y-1/2 right-[68px]  pointer-events-none'>
                  %
                </span>
                <div
                  onClick={() => {
                    setGrowPercentage(prev => {
                      if (prev === 100) return prev;
                      return prev + 1;
                    });
                    dispatch(handleProjectForm({ ...formData, revenue_rate: growPercentage + 1 }));
                  }}
                  className='flex flex-shrink-0 items-center justify-center rounded-full bg-[#C8C4F4] h-8 w-8 cursor-pointer'
                >
                  <Icons.Plus />
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

const StepTwo = () => {
  const formData = useAppSelector(state => state.project.newProjectForm);

  const dispatch = useAppDispatch();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const onFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const file = files[0];
      dispatch(handleProjectForm({ ...formData, files: [...(formData.files ?? []), file] }));
    }
  };

  const handleUploadFile = () => {
    inputRef.current?.click();
  };

  return (
    <div className='flex flex-col'>
      <div className='flex flex-col gap-2'>
        <h3 className='font-bold text-base'>Fill company details</h3>
        <p className='text-[#5E636E] font-medium text-sm max-w-[500px]'>
          Provide some basic information to let our AI agent design a highly optimized and accurate budget for your needs.
        </p>
      </div>
      {formData.files && formData.files?.length > 0 && (
        <div className='flex flex-col mt-4 gap-2'>
          <p className='text-[#717684] font-medium text-sm'>Uploaded files</p>
          <div className='flex flex-col gap-1.5'>
            {formData.files.map((file, index) => (
              <UploadedSourceFile key={index} file={file} />
            ))}
          </div>
        </div>
      )}

      <div className='flex flex-col mt-4 gap-2'>
        <p className='text-[#717684] font-medium text-sm'>Upload new files</p>
        <input type='file' className='hidden' ref={inputRef} onChange={onFileSelected} />
        <button onClick={handleUploadFile} className='w-full border border-[#E9EAEC] rounded-2xl px-6 py-5 flex flex-col'>
          <div className='w-full flex justify-between items-center'>
            <div className='flex gap-3'>
              <span className='border border-[#DEE9FF] rounded-xl p-2.5'>
                <Icons.CSV />
              </span>
              <div className='flex flex-col items-start gap'>
                <h3 className='font-semibold text-sm'>Upload a file</h3>
                <p className='text-[#5E636E] font-medium text-sm'>Upload an CSV, Excel, PDF, Word file. (Max. File size: 25 MB)</p>
              </div>
            </div>

            {/* <button className='bg-[#E9EAEC] p-1 rounded-[4px]'>
              <Icons.Trash className='text-[#717684] h-4 w-4' />
            </button> */}
          </div>
          <div></div>
        </button>
      </div>
    </div>
  );
};

const StepThree = () => {
  const formData = useAppSelector(state => state.project.newProjectForm);

  const [audienceItem, setAudienceItem] = useState<string[]>(formData.audience || []);
  const [platformItem, setPlatformItem] = useState<string[]>(formData.platforms || []);

  const platforms = ["X/Twitter", "Facebook", "Instagram", "LinkedIn", "TikTok", "Google Ads", "Bing Ads", "Pinterest"];

  const dispatch = useAppDispatch();

  const selectAudienceHandler = (audience: any) => {
    setAudienceItem(prev => {
      if (prev.find(item => item === audience)) {
        const newAudience = prev.filter(item => item !== audience);
        dispatch(handleProjectForm({ ...formData, audience: [...newAudience] }));
        return newAudience;
      } else if (prev.length < 3) {
        dispatch(handleProjectForm({ ...formData, audience: [...prev, audience] }));
        return [...prev, audience];
      }
      return [...prev];
    });
  };

  const selectPlatformHandler = (platform: any) => {
    setPlatformItem(prev => {
      if (prev.find(item => item === platform)) {
        const newPlatforms = prev.filter(item => item !== platform);
        dispatch(handleProjectForm({ ...formData, platforms: [...newPlatforms] }));
        return newPlatforms;
      } else if (prev.length < 3) {
        dispatch(handleProjectForm({ ...formData, platforms: [...prev, platform] }));
        return [...prev, platform];
      }
      return [...prev];
    });
  };

  return (
    <div className='flex flex-col'>
      <div className='flex flex-col gap-2'>
        <h3 className='font-bold text-base'>Customize your budget strategy</h3>
        <p className='text-[#5E636E] font-medium text-sm max-w-[500px]'>
          Provide some sources to let our AI agent design a highly optimized and accurate budget for your needs.
        </p>
      </div>
      <div className='flex flex-col mt-4 gap-2'>
        <p className='text-[#717684] font-medium text-sm'>Select inclusion criteria</p>
        <div className='flex flex-col gap-1.5'>
          <InclusionCriteria
            checked={formData.criteria === "overall_budget_spend"}
            onChecked={value => {
              dispatch(handleProjectForm({ ...formData, criteria: "overall_budget_spend" }));
            }}
            title='Overall Budget Spend '
            description='e.g., % program, % people, % tools, % miscellaneous'
          />
          <InclusionCriteria
            checked={formData.criteria === "program_spend_only"}
            onChecked={value => {
              dispatch(handleProjectForm({ ...formData, criteria: "program_spend_only" }));
            }}
            title='% Program Spend Only '
            description='e.g., % for social, content, paid ads, influencer, mobile, email'
          />
        </div>
      </div>
      {formData.criteria != null && formData.criteria != undefined && (
        <>
          <div className='flex flex-col mt-4 gap-2'>
            <p className='text-[#717684] font-medium text-sm'>Select channels you want to include</p>
            <div className='flex gap-1.5'>
              <GoalItem
                className='text-sm font-medium'
                checked={!!audienceItem.find(item => item === "social_media")}
                onChecked={value => {
                  selectAudienceHandler("social_media");
                }}
              >
                Social Media
              </GoalItem>
              <GoalItem
                className='text-sm font-medium'
                checked={!!audienceItem.find(item => item === "paid_ads")}
                onChecked={value => {
                  selectAudienceHandler("paid_ads");
                }}
              >
                Paid Ads
              </GoalItem>
              <GoalItem
                className='text-sm font-medium'
                checked={!!audienceItem.find(item => item === "infuencer")}
                onChecked={value => {
                  selectAudienceHandler("infuencer");
                }}
              >
                Influencer
              </GoalItem>
            </div>
          </div>
          <div className='flex flex-col mt-4 gap-2'>
            <p className='text-[#717684] font-medium text-sm'>Select social media platforms you want to include</p>
            <div className='flex w-full flex-wrap gap-1.5'>
              {platforms.map((item, index) => {
                let icon: React.ReactNode | null = null;
                const iconName = item.toLowerCase();

                if (iconName === "facebook") {
                  icon = <Icons.Facebook className='mr-1' />;
                } else if (iconName === "instagram") {
                  icon = <Icons.Instagram className='mr-1' />;
                } else if (iconName.includes("tiktok")) {
                  icon = <Icons.TikTok className='mr-1' />;
                } else if (iconName.includes("google")) {
                  icon = <Icons.GoogleAds className='mr-1' />;
                } else if (iconName.includes("twitter")) {
                  icon = <Icons.TwitterOrX className='mr-1' />;
                } else if (iconName.includes("bing")) {
                  icon = <Icons.BingAds className='mr-1' />;
                } else if (iconName.includes("linkedin")) {
                  icon = <Icons.LinkedIn className='mr-1' />;
                } else if (iconName.includes("email")) {
                  icon = <Icons.Email className='mr-1' />;
                } else if (iconName.includes("pinterest")) {
                  icon = <Icons.Pinterest className='mr-1' />;
                }

                return (
                  <GoalItem key={index} checked={!!platformItem.find(el => el === item)} onChecked={() => selectPlatformHandler(item)}>
                    {icon}
                    {item}
                  </GoalItem>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const UploadedSourceFile = ({ file }: { file: File }) => {
  return (
    <div className='border border-[#E9EAEC] rounded-lg p-1.5 flex gap-3'>
      <span className='border border-[#F7F7F8] bg-[#F7F7F8] rounded-xl h-[2.25rem] w-[2.25rem] flex items-center justify-center'>
        <Icons.CSV className='h-5 w-5' />
      </span>
      <div className='flex flex-col gap'>
        <h3 className='font-semibold text-sm'>{file.name}</h3>
        <p className='text-[#A9ACB4] font-normal text-xs'>{file.type}</p>
      </div>
    </div>
  );
};

const InclusionCriteria = ({
  checked,
  title,
  description,
  onChecked,
}: {
  checked?: boolean;
  title: string;
  description: string;
  onChecked?: (value: boolean) => void;
}) => {
  const [value, setValue] = useState(checked || false);

  const handleClick = () => {
    setValue(!value);
    onChecked && onChecked(!value);
  };

  return (
    <div onClick={handleClick} className='border border-[#E9EAEC] rounded-lg p-1.5 flex gap-3'>
      <button
        onClick={handleClick}
        type='button'
        className='h-[2.25rem] w-[2.25rem] rounded-full bg-[#F7F7F8] flex items-center justify-center'
      >
        <span
          className={cn(
            " h-5 w-5 border rounded-full flex items-center justify-center",
            checked == true ? "bg-purple border-purple " : "bg-white border-[#E9EAEC] ",
          )}
        >
          {checked == true && <Icons.Check className='text-white' />}
        </span>
      </button>
      <div className='flex flex-col gap'>
        <h3 className='font-semibold text-sm'>{title}</h3>
        <p className='text-[#A9ACB4] font-normal text-xs'>{description}</p>
      </div>
    </div>
  );
};

const BtnGroup = ({
  disabledPrevHandler,
  disabledNextHandler,
  nextStepHandler,
  prevStepHandler,
  disabledSkipHandler,
  showSkip = true,
}: {
  nextStepHandler: () => void;
  prevStepHandler?: () => void;
  disabledNextHandler?: boolean;
  disabledPrevHandler?: boolean;
  disabledSkipHandler?: boolean;
  showSkip?: boolean;
}) => {
  return (
    <div className='w-full flex justify-between'>
      <div className='flex gap-4'>
        {showSkip == true && (
          <button
            disabled={disabledSkipHandler == true}
            className='disabled:cursor-not-allowed disabled:text-[#C8CAD0] text-base text-[#766CE4] font-bold  flex items-center'
          >
            Skip
          </button>
        )}
      </div>
      <div className='flex gap-4'>
        <button
          onClick={() => (disabledNextHandler == true ? null : prevStepHandler?.())}
          disabled={disabledPrevHandler == true}
          className='group text-base text-purple font-bold  flex items-center disabled:cursor-not-allowed disabled:text-[#C8CAD0]'
        >
          <Icons.ArrowLeft className=' mr-2 ' />
          Back
        </button>
        <Button
          onClick={() => (disabledNextHandler == true ? null : nextStepHandler())}
          disabled={disabledNextHandler == true}
          className='!h-[32px] !rounded-lg !py-[7px] !px-[42px] !w-[120px]'
        >
          Next
        </Button>
      </div>
    </div>
  );
};
