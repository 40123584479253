import { useContext, useEffect } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableProvided,
  DraggableStateSnapshot,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";

import { IChannelData } from "api/budget/types";

import { CHANNEL_FLOW_ICONS } from "constants/channels.constants";

import { Item, Container, getContainerFromProjectChannels, removeDashFromString } from "utils/budget.utils";

import { useAppSelector } from "hooks/appHooks";

import { ConnectSourceContext } from "pages/budget/ConnectDataSource";

import { ReactComponent as CloseIcon } from "assets/icons/Close.svg";
import { ReactComponent as DraghandleIcon } from "assets/icons/draghandle.svg";

import { categoriesData, projectChannelsData } from "./jsonExamples";


// Define the type for the move result
interface MoveResult {
  [key: string]: Item[];
}

type ConfirmCategoriesProps = {
  error: boolean;
};

// Function to reorder items within the same list
const reorder = (list: Item[], startIndex: number, endIndex: number): Item[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

// Function to move items between lists
const move = (
  source: Item[],
  destination: Item[],
  droppableSource: { droppableId: string; index: number },
  droppableDestination: { droppableId: string; index: number },
): MoveResult => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result: MoveResult = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

// Get styles for the list container
const getListContainerStyle = ( isFullWidth: boolean): React.CSSProperties => ({
  borderWidth: 1,
  borderRadius: 12,
  borderColor: isFullWidth ? "#C8CAD0" : "#E9EAEC",
  borderStyle: isFullWidth ? "dashed" : "solid",
  overflow: "hidden",
  gridColumnStart: isFullWidth ? 1 : "auto",
  gridColumnEnd: isFullWidth ? 5 : "auto",
  height: "100%",
});

// Get styles for the list itself
const getListStyle = (isDraggingOver: boolean, isFullWidth: boolean): React.CSSProperties => ({
  background: isDraggingOver ? "#E9EAEC" : "white",
  padding: isFullWidth ? 16 : 8,
  display: "flex",
  flexWrap: "wrap",
  overflow: "hidden",
  height: "100%",
  alignContent: "flex-start",
});

// Main component
const ConfirmCategories = ({ error }: ConfirmCategoriesProps) => {
  const { categories, setCategories }: { categories: Container[]; setCategories: any } = useContext(ConnectSourceContext);
  const projectChannels: IChannelData[] = useAppSelector(state => state.channels.channels);

  const makeEmptyContainer = (id: string) => {
    return {
      id: id,
      content: id,
      items: [],
    };
  };
  const getContainerFromId = (id: string): Container => {
    const el = categories?.find(item => item.id === String(id)) || makeEmptyContainer(id);
    console.log("getContainerFromId", id, categories);
    return el;
  };

  // Get styles for draggable items
  const getItemStyle = (isDragging: boolean, draggableStyle: React.CSSProperties, channel: IChannelData): React.CSSProperties => ({
    userSelect: "none",
    padding: 8,
    paddingTop: 6,
    paddingBottom: 6,
    margin: `0 6px ${6}px 0`,
    background: isDragging ? channel.color.primary : "#F7F7F8",
    borderRadius: 6,
    height: "fit-content",
    ...draggableStyle,
  });

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) return;

    const sInd = source.droppableId;
    const dInd = destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(getContainerFromId(sInd).items, source.index, destination.index);
      const newState = [...categories];
      newState[categories.findIndex(item => item.id === sInd)] = {
        ...getContainerFromId(sInd),
        items,
      };
      setCategories(newState);
    } else {
      const result = move(getContainerFromId(sInd).items, getContainerFromId(dInd).items, source, destination);
      const newState = categories.map(item => (result[item.id] ? { ...item, items: result[item.id] } : item));
      setTimeout(() => setCategories(newState), 0);
    }
  };
  const moveToUnassignedCategories = (item: Item) => () => {
    const newState = categories.map(container => {
      if (container.id === "0") {
        // Add item to unassigned container
        return {
          ...container,
          items: [...container.items, item]
        };
      } else {
        // Remove item from its original container
        return {
          ...container,
          items: container.items.filter(i => i.id !== item.id)
        };
      }
    });
  
    setCategories(newState);
  };
  interface DroppableComponentProps {
    channel: IChannelData;
    isFullWidth?: boolean;
  }

  const DroppableComponent = ({ channel, isFullWidth = false }: DroppableComponentProps) => {
    const Icon = CHANNEL_FLOW_ICONS?.[removeDashFromString(channel?.icon) as keyof typeof CHANNEL_FLOW_ICONS];
    const container = getContainerFromProjectChannels(channel, categories);

    return (
      <Droppable key={channel.channel_id} droppableId={String(channel.channel_id)} direction='horizontal'>
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
          <div style={getListContainerStyle(isFullWidth)}>
            {!isFullWidth && (
              <>
                <span className='bg-rose-600 h-4 w-full block' style={{ backgroundColor: channel.color.primary }} />
                <div className='flex flex-row items-center px-3 py-1'>
                  <span
                    style={{ backgroundColor: channel.color.light }}
                    className='flex items-center justify-center h-4 w-4 mr-1.5 rounded'
                  >
                    { Icon && <Icon className='h-3 w-3' />}
                  </span>
                  <p className='text-sm font-semibold text-[#2E323A]'>{channel.name}</p>
                </div>
                <hr className='w-full' />
              </>
            )}
            <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver, isFullWidth)} {...provided.droppableProps}>
              {container.items.map((item: any, index: any) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style!, channel)}
                    >
                      <div className='flex flex-row items-center text-sm'>
                        <DraghandleIcon className='mr-1' />
                        {item.content}
                       
                          {!isFullWidth &&<button className='ml-auto' onClick={moveToUnassignedCategories(item)}>
                            <CloseIcon className='ml-1 mt-[2px]' />
                          </button>}
                        
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              {isFullWidth && <div className="text-xs font-medium text-[#5E636E] text-center w-full">Drag and drop categories here if you want to remove them temporarily</div>}
            </div>
          </div>
        )}
      </Droppable>
    );
  };

  console.log("Categories", categories);
  console.log("Project Channels", projectChannels);
  
  return (
    <div className=''>
      <div className='grid grid-cols-4 gap-6 rounded-[22px] bg-white flex-wrap'>
        <DragDropContext onDragEnd={onDragEnd}>
          {categories && projectChannels?.map((channel: IChannelData) =>
            channel.channel_id !== 0 ? (
              <DroppableComponent key={channel.channel_id} channel={channel} isFullWidth={false} />
            ) : (
              <>
              <DroppableComponent key={channel.channel_id} channel={channel} isFullWidth={true} />
              <div className="col-span-4">
            <p className='text-base font-bold text-[#2E323A]'>Assigned Categories</p>
            <div className='flex flex-row items-center mt-4'>
              <p className='text-sm font-medium text-[#333740]'>
              You can rearrange the categories by dragging the tags to the appropriate group
              </p>
            </div>
          </div>
          </>
            ),
          )}
        </DragDropContext>
      </div>
    </div>
  );
};

export default ConfirmCategories;
