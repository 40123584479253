import { EmblaOptionsType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AgentItem } from "api/agent/type";
import { TaskItem } from "api/task/type";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { usePaymentStatus } from "hooks/payments/usePaymentStatus";
import { useInitializePendo } from "hooks/useInitializePendo";

import { getAgentsList } from "storage/actions/agent";
import { getTasksList } from "storage/actions/task";

import { Icons } from "shared/components/icons";
import { DashboardLayout } from "shared/layouts/DashboardLayout";

import InsightCard from "page-components/dashboard/dashboard-insights/InsightCard";
import EmmaAi from "page-components/dashboard/emma-ai";

import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";
import bgDot from "assets/icons/bg-dot.svg";
import BrandCategories from "assets/png/brand-categories.png";
import Integration from "assets/png/integration.png";

import { cn } from "lib/utils";

import "./dashboard.css";

export default function Dashboard() {
  usePaymentStatus();
  useInitializePendo();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const emblaOptions: EmblaOptionsType = {};

  const [emblaRef, emblaApi] = useEmblaCarousel(emblaOptions);

  const taskList = useAppSelector(state => state.task.taskList);
  const agents = useAppSelector(state => state.agent.agents);

  const tasks = useMemo(() => {
    if (!taskList) return {};

    const data = taskList.slice(0, 6);
    const taskMap: { [key: number]: TaskItem[] } = {};

    for (let i = 0; i < data.length; i += 2) {
      taskMap[i / 2 + 1] = data.slice(i, i + 2);
    }

    return taskMap;
  }, [taskList]);

  const emmaAgent = useMemo(() => {
    if (!agents) return null;

    return agents.find((agent: AgentItem) => agent.id === 1);
  }, [agents]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;

    if (emblaApi.canScrollNext()) {
      emblaApi.scrollNext();
    } else {
      emblaApi.scrollTo(0);
    }
  }, [emblaApi]);

  const handleGoToTasks = () => {
    navigate("/projects");
  };

  const handleGoToIntegrations = () => {
    navigate("/integrations");
  };

  const handleGoToBrandkit = () => {
    navigate("/brandkit");
  };

  useEffect(() => {
    dispatch(getAgentsList({ page: 1 }));
    dispatch(getTasksList({ page: 1 }));
  }, []);

  return (
    <DashboardLayout title={emmaAgent?.title ?? "Get Started"} containerClassName='bg-white bg-bg-gradient'>
      <div className='w-full max-w-6xl h-full p-6 m-auto '>
        <div className='md:mt-20 flex flex-col md:flex-row w-full md:justify-between gap-2'>
          <EmmaAi agent={emmaAgent} />

          <div className='flex flex-col gap-4 relative w-full md:w-[36%]'>
            <div className='embla__viewport' ref={emblaRef}>
              <div className='embla__container'>
                {Object.keys(tasks).map((key, index) => {
                  const data = tasks[parseInt(key) as 1 | 2];

                  if (!data || data.length <= 0) return null;

                  return (
                    <div className='embla__slide' key={index}>
                      <div className={cn("flex flex-col gap-4 w-full h-full")}>
                        {data.map((task, i) => (
                          <SampleTask key={i} task={task} />
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                className='h-full w-[80px] absolute top-0 z-30 right-0 flex items-center justify-center'
                style={{
                  background: "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 38%)",
                }}
              >
                <div
                  onClick={onNextButtonClick}
                  className='cursor-pointer w-[54px] h-[54px] p-[15px] flex items-center z-30 justify-center bg-white border border-[#E8E9F0] rounded-full shadow-custom -mt-[50px]'
                >
                  <ArrowDownIcon className='-rotate-90' />
                </div>
              </div>
            </div>

            <button onClick={handleGoToTasks} className='text-[#766CE4] font-bold text-base flex items-center gap-1'>
              <span className='underline'>Browse all tasks</span>
              <Icons.ArrowTopRight className='text-[#766CE4] h-[14px] w-[14px]' />
            </button>
          </div>
        </div>
        <div className='md-5 mt-5 md:mt-28 grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-10'>
          <InsightCard
            className='lg:!w-full overflow-hidden !h-[17.5rem]'
            title={"Setup Company Profile "}
            description={"Set up your profile for personalized insights and optimized marketing strategies."}
            bg={bgDot}
            onButtonClick={handleGoToBrandkit}
            icon={
              <div className='flex gap-1 items-center'>
                <Icons.Brand className='w-4 h-4' />
                <p className='text-[#717684] text-xs font-medium'>Brandkit</p>
              </div>
            }
          >
            <div className='top-[10px] absolute'>
              <img src={BrandCategories} alt='Brand Categories' className='' />
            </div>
          </InsightCard>
          <InsightCard
            className='lg:!w-full overflow-hidden !h-[17.5rem]'
            title={"Transform your data into insights  "}
            description={"Easily connect your marketing platforms now in just a few clicks to get your personalized  insights."}
            bg={bgDot}
            onButtonClick={handleGoToIntegrations}
            icon={
              <div className='flex gap-1 items-center'>
                <Icons.Integration className='w-4 h-4' />
                <p className='text-[#717684] text-xs font-medium'>Integrations</p>
              </div>
            }
          >
            <div className='top-[10px] absolute mx-4'>
              <img src={Integration} alt='Integration' className='' />
            </div>
          </InsightCard>
        </div>
      </div>
    </DashboardLayout>
  );
}

const SampleTask = ({ task }: { task: TaskItem }) => {
  return (
    <div className='bg-[#F7F7F8] rounded-2xl md:w-[340px] md:max-w-[390px] p-5'>
      <div className='flex flex-col gap-4'>
        <div className='flex gap-2'>
          <Icons.Calender className='w-4 h-4' />
          <p className='text-[#717684] font-medium text-xs'>Sample task</p>
        </div>
        <p className='text-base font-semibold text-[#14191A]'>{task.task_name}</p>
      </div>
    </div>
  );
};
