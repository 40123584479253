import React from "react";

import { MembershipPlanDetails } from "interfaces/memberships.interfaces";

import { ReactComponent as BriefCaseIcon } from "assets/icons/briefcase-1.svg";
import { ReactComponent as RocketIcon } from "assets/icons/rocket.svg";
import { ReactComponent as StarIcon } from "assets/icons/star.svg";

import { MembershipCardsContainer } from "../page-components/memberships/MembershipCardsContainer";
import { getMembershipYearOrMonthLabel } from "../utils/memberships.utils";

import { UserPlans } from "./user.constants";

export const MEMBERSHIP_PLANS_FREE_ADVANTAGES = [
  {
    checked: true,
    label: "1 Budget template",
  },
  {
    checked: true,
    label: "1 Co-pilot workflow",
  },
  {
    checked: true,
    label: "1 Integration",
  },
  {
    checked: true,
    label: "1 Data import",
  },
  {
    checked: false,
    label: "Early access to new product releases",
  },
];


export const MEMBERSHIP_PLANS_PRO_ADVANTAGES = [
  {
    checked: true,
    label: "Unlimited budget workspaces",
  },
  {
    checked: true,
    label: "10 workflows",
    outdated: true,
    newLabel: "Unlimited",
  },
  {
    checked: true,
    label: "AI playground",
  },
  {
    checked: true,
    label: "1 user seat",
  },
];

export const MEMBERSHIP_PLANS_STARTER_ADVANTAGES = [
  {
    checked: true,
    label: "Lifetime pricing*",
  },
  {
    checked: true,
    label: "Unlimited budget templates",
  },
  {
    checked: true,
    label: "10 Co-pilot workflows",
  },
  {
    checked: true,
    label: "3 Integrations**",
  },
  {
    checked: true,
    label: "Unlimited data imports",
  },
  {
    checked: true,
    label: "Early access to new product releases",
  },
];


export const MEMBERSHIP_PLANS_ENTERPRISE_ADVANTAGES = [
  {
    checked: true,
    label: "Full suite of AI agents",
  },
  {
    checked: true,
    label: "Unlimited workflow automation",
  },
  {
    checked: true,
    label: "Premium integrations",
  },
  {
    checked: true,
    label: "Custom dashboards and reporting",
  },
  {
    checked: true,
    label: "Unlimited data import and export",
  },
  {
    checked: true,
    label: "Enterprise SSO & Authentication",
  },
  {
    checked: true,
    label: "Advanced roles and permissions",
  },
  {
    checked: true,
    label: "White glove support",
  },
];

export const getMembershipsPlansDetails = (
  isMembershipAlreadyExists?: boolean,
  isSimplified?: boolean,
): Record<UserPlans, MembershipPlanDetails> => ({
  [UserPlans.FREE]: {
    icon: RocketIcon,
    priceLabel: () => "7-day free trial",
    description: "Begin your journey to data-driven marketing with our basic plan.",
    advantages: MEMBERSHIP_PLANS_FREE_ADVANTAGES,
  },
  [UserPlans.STARTER]: {
    icon: StarIcon,
    priceLabel: isYearly => `per user / ${getMembershipYearOrMonthLabel(isYearly)}`,
    description: "Advanced AI features to create channel-specific budgets and campaigns.",
    advantages: MEMBERSHIP_PLANS_STARTER_ADVANTAGES,
    showMostValuable: !isSimplified,
    labelContent: "50% off",
    buttonContent: "Upgrade",
  },
  [UserPlans.PRO]: {
    icon: StarIcon,
    priceLabel: isYearly => `per user / ${getMembershipYearOrMonthLabel(isYearly)}`,
    description: "Advanced AI features to create channel-specific budgets and campaigns.",
    advantages: MEMBERSHIP_PLANS_PRO_ADVANTAGES,
    // labelContent: "50% off for 3 months",
    buttonContent: isMembershipAlreadyExists ? "Upgrade" : "",
  },
  [UserPlans.ENTERPRISE]: {
    icon: BriefCaseIcon,
    priceLabel: () => "",
    description: "Customizable solutions and advanced AI features to meet your unique needs.",
    advantages: MEMBERSHIP_PLANS_ENTERPRISE_ADVANTAGES,
    buttonContent: "Contact Sales",
  },
});

export const getMembershipsTabs = ({ isSimplified }: { isSimplified?: boolean }) => [
  {
    id: "monthly",
    title: "Monthly",
    component: () => <MembershipCardsContainer isSimplified={isSimplified} />,
  },
  {
    id: "yearly",
    title: "Yearly",
    description: "(Save 50%)",
    component: () => <MembershipCardsContainer isSimplified={isSimplified} isYearly />,
  },
];
