import React, { createContext, useContext, useState, ReactNode, useMemo } from "react";

interface AIPanelContextProps {
  isOpen: boolean;
  showAsSheet: boolean;
  openPanel: (asSheet?: boolean) => void;
  closePanel: () => void;
  page: "active" | "history";
  switchPage: (page: "active" | "history") => void;
}

const AIPanelContext = createContext<AIPanelContextProps | undefined>(undefined);

export const AIPanelProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showAsSheet, setShowAsSheet] = useState(false);
  const [page, setPage] = useState<"active" | "history">("active");

  const openPanel = (asSheet = false) => {
    setShowAsSheet(asSheet);
    setIsOpen(true);
  };

  const closePanel = () => {
    setIsOpen(false);
    setShowAsSheet(false);
  };

  const switchPage = (newPage: "active" | "history") => {
    setPage(newPage);
  };

  const value = useMemo<AIPanelContextProps>(
    () => ({ isOpen, showAsSheet, openPanel, closePanel, page, switchPage }),
    [isOpen, showAsSheet, page, switchPage, closePanel],
  );

  return <AIPanelContext.Provider value={value}>{children}</AIPanelContext.Provider>;
};

export const useAI = () => {
  const context = useContext(AIPanelContext);
  if (!context) {
    throw new Error("useAIPanel must be used within an AIPanelProvider");
  }
  return context;
};
