import React from "react";

import { ReactComponent as ArrowIcom } from "assets/icons/arrow-up-right.svg";
import { ReactComponent as StatIcon } from "assets/icons/stat-up.svg";
import startProjectInsights from "assets/png/start-project-insights-faded.png";

import { cn } from "lib/utils";

interface InsightCardProps {
  onButtonClick: () => void;
  title: string;
  description: string;
  bg: any;
  children?: any;
  className?: string;
  icon?: React.ReactNode;
}

const InsightCard: React.FC<InsightCardProps> = ({ onButtonClick, icon, title, description, bg, children, className }) => {
  return (
    <div
      className={cn(
        "p-6 w-full lg:w-[400px] relative flex flex-col items-center transition-all duration-300 ease-in-out justify-end bg-white h-64 border-[1px] group hover:border-[#9189E9] hover:shadow-ring1 border-[#C8CAD0] bg-cover bg-center bg-no-repeat rounded-[20px]",
        className,
      )}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className={cn("flex gap-4 justify-between", "")}>
        <div className='flex w-[90%] flex-col gap-2'>
          {icon && icon}
          <h1 className='text-base leading-default font-bold text-[#14191A]'>{title}</h1>
          <p className='text-[#717684] leading-[22.4px] text-sm font-medium'>{description}</p>
        </div>
        <button
          className='h-9 flex items-center transition-all duration-300 ease-in-out justify-center w-9 bg-white rounded-[100%] border-[1px] border-[#E9EAEC] stroke-[#1F2228] group-hover:stroke-white group-hover:bg-[#766CE4]'
          onClick={onButtonClick}
        >
          <ArrowIcom />
        </button>
      </div>
      {children && children}
    </div>
  );
};

export default InsightCard;
