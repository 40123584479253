import api from "..";

import { TaskItem } from "./type";

const BASE_API = process.env.REACT_APP_API_URL;

export const getAllTasks = async (page: number): Promise<TaskItem[]> => {
  const data: TaskItem[] = await api(`${BASE_API}/tasks?page=${page}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return data;
};
