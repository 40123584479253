import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";

import { TaskItem } from "api/task/type";

import { ProjectListItem } from "interfaces/projects.interfaces";

import { getTasksList } from "storage/actions/task";
import { TaskState } from "storage/slices/task-slice";

export function getTasksListReducer(builder: ActionReducerMapBuilder<TaskState>) {
  builder.addCase(getTasksList.fulfilled, (state, action: PayloadAction<TaskItem[]>) => {
    state.taskList = action.payload;
  });
}
