import { ActionReducerMapBuilder, PayloadAction } from "@reduxjs/toolkit";

import { AgentItem } from "api/agent/type";

import { getAgentsList } from "storage/actions/agent";
import { AgentState } from "storage/slices/agent-slice";

export function getAgentsListReducer(builder: ActionReducerMapBuilder<AgentState>) {
  builder.addCase(getAgentsList.fulfilled, (state, action: PayloadAction<AgentItem[]>) => {
    state.agents = action.payload;
  });
}
