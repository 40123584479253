import cn from "classnames";
import React from "react";
import ReactDOM from "react-dom";

import { INTEGRATION_ICONS } from "constants/integrations.constants";

import Button from "shared/components/auth-buttons/Button";

import { ReactComponent as CloseWhiteBtn } from "assets/icons/cross-rounded-white.svg";
import { ReactComponent as ReviewOverlayIcon2 } from "assets/icons/Logo.svg";
interface OverlayProps {
    onClose: () => void; // TODO: can we make this take the user to the Connected Sources page?
    onOpen: () => void;
}

const PopupConfrimOverlay: React.FC<OverlayProps> = ({ onClose,onOpen}) => {
  return ReactDOM.createPortal(
    <div id="overlay-general">
       
      <div className='flex flex-col items-center justify-center bg-white p-20 rounded-3xl relative'>
      <CloseWhiteBtn height={36} width={36}  className='absolute top-6 right-6' onClick={onClose}/>
        
        <div className="">
            <p className="text-3xl font-semibold text-[#1F2228]">Hold Tight – Just One More Step!</p>
        </div>
        <div className="mt-6 max-w-486 text-center">
            <p className="text-base font-medium text-[#5E636E] leading-7">To complete the integration with Google Sheets, you need to select a spreadsheet to connect. Click the Open button below to proceed with the selection.</p>
        </div>
        <Button classes="!w-fit mt-10 px-[42px] rounded-xl" onClick={onOpen} >
            <p>Open</p>
        </Button>
      </div>
    </div>,
    document.body // Render in the document body to cover the entire viewport
  );
};

export default PopupConfrimOverlay;
