import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";

import { selectIsWorkflowLoading } from "storage/selectors/workflows";
import { setIsCollapsed } from "storage/slices/sidebar-slice";

import SpinnerLoader from "shared/components/loader-screen/SpinnerLoader";
import { DashboardLayout } from "shared/layouts/DashboardLayout";

import { PerformancePlanContentBlock } from "page-components/performance-plan/performance-plan-content/PerformancePlanContentBlock";
import { PerformancePlanSidebar } from "page-components/performance-plan/performance-plan-sidebar/PerformancePlanSidebar";

export const PerformancePlan = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsWorkflowLoading);
  useEffect(() => {
    // dispatch(setIsCollapsed(true))
  }, []);

  if (isLoading) return <SpinnerLoader />;
  
  return (
    <DashboardLayout title='Workflow'>
      <div className='flex h-full gap-x-6'>
        <PerformancePlanSidebar />
        <PerformancePlanContentBlock />
      </div>
    </DashboardLayout>
  );
};
