import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  children?: ReactNode;
  className?: string;
  isShown?: boolean;
}

const BlurOverlay = ({ children, className, isShown }: Props) => {
  if (!isShown) return null;

  return (
    <div className={twMerge("w-full h-full font-plusJakartaSans absolute top-0 left-0 z-30 bg-white/80 backdrop-blur-sm", className)}>
      {children}
    </div>
  );
};

export default BlurOverlay;
