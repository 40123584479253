import api from "..";

import { AgentItem } from "./type";
const BASE_API = process.env.REACT_APP_API_URL;

export const getAllAgents = async (page: number): Promise<AgentItem[]> => {
  const data: AgentItem[] = await api(`${BASE_API}/agents?page=${page}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  return data;
};
