import { useEffect, useMemo, useState } from "react";
import useDrivePicker from "react-google-drive-picker";
import { useLocation, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { createConnection, generateCredentialForAppIntegration, saveOauth2Credential } from "api/integrations";

import { ROUTES } from "constants/routes.constants";
import { INPUT_SHARED_CLASSNAME } from "constants/shared/fom-elements.constants";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { useString } from "hooks/useString";

import { getAllConnectSources } from "storage/slices/integrations-slice";

import Alert from "shared/components/toasts";

import ConnectedOverlay from "./ConnectSourceList/ConnectedOverlay";
import PopupConfrimOverlay from "./ConnectSourceList/PopupComfirmOverlay";
import SourceCards from "./ConnectSourceList/SourceCards";

type localStorageIntegrationType = {
  integration_id: number;
  display_name: string;
  scopes: string[];
  supportsRefreshToken: boolean;
  enabled: boolean;
  logo: string;
  description: string;
  category: string;
  service_id: number | null;
};

const ConnectSourceList = () => {
  const [search, setSearch] = useString("");
  const [showConnectedOverlay, setShowConnectedOverlay] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState<any>(null);
  const [connecting, setConnecting] = useState(false);
  const [middleWareData, setMiddleWareData] = useState<any>({ access_token: "", credential_id: "", integration_id: "" });
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const connectSourcesList = useAppSelector(state => state.integrations.connectSources);
  const categoryList = ["CRM", "Social Media", "Accounting & ERP", "E-Commerce", "Tracking", "Data files"];
  const [openPicker, authResponse] = useDrivePicker();

  const handleIntegration = (item: localStorageIntegrationType) => {
    // console.log("Integration ID", integration_id);
    const { integration_id } = item;
    localStorage.setItem("selectedIntegration", JSON.stringify(item));
    console.log("Integration Item", item);
    //   console.log("HubSpot");
    //   const url = "http://localhost:3000/integrations/connect-sources"
    //   window.location.href = `https://app.hubspot.com/oauth/authorize?client_id=46a9699b-6de9-4e5a-aef4-6e38aaeffb22&scope=crm.lists.read%20crm.objects.companies.read%20crm.objects.contacts.read%20crm.objects.deals.read%20crm.objects.feedback_submissions.read%20crm.objects.owners.read%20crm.objects.subscriptions.read%20crm.schemas.companies.read%20crm.schemas.contacts.read%20crm.schemas.deals.read%20oauth&redirect_uri=${url}&response_type=code&state={%22uuid%22:%22e0956e6a-891e-496f-8ba3-48ffb7c52aed%22,%22integrationId%22:%221%22,%22userId%22:5}`
    //   return
    generateCredentialForAppIntegration(integration_id)
      .then(res => {
        window.location.href = res.authorizationUrl;
      })
      .catch(err => {
        Alert("error", err.message, "Error");
      });
  };
  const handleSearch = (e: any) => {
    setSearch(e.target.value);
  };
  const filteredSearchList = useMemo(() => {
    const data: any = {
      CRM: [],
      "Social Media": [],
      "Accounting & ERP": [],
      "E-Commerce": [],
      Tracking: [],
      "Data files": [],
    };
    connectSourcesList.forEach(item => {
      if (item.display_name.toLowerCase().includes(search.toLowerCase())) data[item.category].push(item);
    });
    const newdata = categoryList.map(item => {
      return {
        type: item,
        options: data[item].map((item: any) => {
          return { ...item, logo: item.logo, onConnect: () => handleIntegration(item), disabled: false };
        }),
      };
    });
    return newdata;
  }, [search, connectSourcesList]);
  const queryToObject = (query: string) => {
    const parameters = new URLSearchParams(query);
    return Object.fromEntries(parameters.entries());
  };
  const createAirbyteConnection = (apiData: any) => {
    setConnecting(true);
    createConnection(apiData)
      .then(_ => {
        // refresh the list
        dispatch(getAllConnectSources());
      })
      .catch(err => {
        console.log("Something went wrong in createConnection", err);
        Alert("error", err.message, "Error");
        setShowConnectedOverlay(false);
      })
      .finally(() => {
        setConnecting(false);
      });
  };
  const openGoogleSheetPicker = () => {
    if (!process.env.REACT_APP_OAUTH_CLIENT_ID || !process.env.REACT_APP_GOOGLE_API_KEY) {
      Alert("error", "There seems to be a problem. Please try again later", "Error");
      return;
    }
    openPicker({
      clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
      developerKey: process.env.REACT_APP_GOOGLE_API_KEY,
      viewId: "SPREADSHEETS",
      token: middleWareData.access_token,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      // customViews: customViewsArray, // custom view
      callbackFunction: data => {
        console.log("Data", data);
        if (data.action === "cancel") {
        }
        if (data.action === "picked") {
          setShowConnectedOverlay(true);
          const apiData = {
            credential_id: middleWareData.credential_id,
            integration_id: middleWareData.integration_id,
            integration_data: { spreadsheetId: data.docs?.map(item => item.url).at(0) },
          };
          createAirbyteConnection(apiData);
        }
      },
    });
  };
  const checkIntegrationType = (res: any) => {
    const selectedIntegration: string | null = localStorage.getItem("selectedIntegration");
    console.log("Selected Integration", selectedIntegration);
    // Continue Work here
    if (selectedIntegration) {
      const selectedIntegrationData: localStorageIntegrationType = JSON.parse(selectedIntegration);
      switch (selectedIntegrationData.display_name) {
        case "Google Sheets":
          setSelectedIntegration(selectedIntegrationData);
          setMiddleWareData({
            access_token: res.data.access_token,
            credential_id: res.data.credential_id,
            integration_id: res.data.integration_id,
          });
          break;

        default:
          setSelectedIntegration(selectedIntegrationData);
          setShowConnectedOverlay(true);
          const apiData = { credential_id: res.data.credential_id, integration_id: res.data.integration_id };
          createAirbyteConnection(apiData);
          break;
      }
    }
  };
  // const openThePicker = ()=>{
  //   openPicker({
  //     clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
  //     developerKey: "AIzaSyDTFSohShED83QG61RAVrB0vsaBgKgvfQg",
  //     viewId: "SPREADSHEETS",
  //     // token: token, // pass oauth token in case you already have one
  //     showUploadView: true,
  //     showUploadFolders: true,
  //     supportDrives: true,
  //     multiselect: false,
  //     // customViews: customViewsArray, // custom view
  //     callbackFunction: (data) => {
  //       if (data.action === "cancel") {
  //         console.log("User clicked cancel/close button")
  //       }
  //       console.log(data)
  //     },
  //   })
  // }
  useEffect(() => {
    const payload = queryToObject(location.search.split("?")[1]);
    console.log("Payload", payload);
    const code = payload && payload.code;
    const state = payload && payload.state;
    const error = payload && payload.error;
    console.log("State", state);
    console.log("Error", error);
    if (code && state) {
      saveOauth2Credential(code, state)
        .then(res => {
          // this should either return the credential or create the connection itself on the backend
          window.history.replaceState(null, "Brainvoy", ROUTES.connectedSources);

          checkIntegrationType(res);
        })
        .catch(err => {
          console.log("Something went wrong in saveOauth2Credential", err);
          Alert("error", err.message, "Error");
        });
    }
    dispatch(getAllConnectSources());
  }, []);

  return (
    <div className='flex-1 p-6'>
      <p className='text-2xl font-semibold text-[#333740] '>Connect Sources</p>
      {/* <button onClick={openThePicker}>OPen Picker</button> */}
      <div className='relative mt-3'>
        <div className='absolute cursor-pointer inset-y-0 start-0 flex items-center ps-3 '>
          <svg style={{ cursor: "pointer" }} width='16' height='16' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z'
              stroke='#2C1A49'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path opacity='0.4' d='M22 22L20 20' stroke='#2C1A49' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
          </svg>
        </div>
        <input
          type='search'
          className={twMerge("max-w-[325px] py-[9px] pl-10 pr-3.5 ", INPUT_SHARED_CLASSNAME)}
          placeholder='Search budgets'
          required
          value={search}
          onChange={handleSearch}
        />
      </div>
      {filteredSearchList.map((item: any) => (item.options.length > 0 ? <SourceCards item={item} key={item.type} /> : null))}
      {showConnectedOverlay && (
        <ConnectedOverlay
          onClick={() => {
            setShowConnectedOverlay(false);
          }}
          selectedIntegration={selectedIntegration}
          connecting={connecting}
        />
      )}
      {middleWareData?.access_token && (
        <PopupConfrimOverlay
          onClose={() => {
            setMiddleWareData(null);
          }}
          onOpen={() => {
            openGoogleSheetPicker();
            setMiddleWareData(null);
          }}
        />
      )}
    </div>
  );
};

export default ConnectSourceList;
