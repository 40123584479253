import React, { ReactNode, useEffect } from "react";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";

import { setIsCollapsed } from "storage/slices/sidebar-slice";
import { RootState } from "storage/store";

import AIPanel from "shared/components/ai-panel";
import { AIPanelProvider, useAI } from "shared/components/ai-panel/provider";
import { Header } from "shared/components/Header";
import { preventAutoCloseRoutes } from "shared/components/side-navbar/nav-config";

import { cn } from "lib/utils";

import Popup from "../components/basePopup/Popup";
import SpinnerLoader from "../components/loader-screen/SpinnerLoader";
import NavBar from "../components/side-navbar/NavBar";

const MAIN_CLASSNAME = "flex w-full h-full overflow-hidden bg-[#F7F9FB]";

interface Props {
  children: ReactNode;
  title?: string;
  containerClassName?: string;
  isScrollable?: boolean;
  headerExtraContent?: ReactNode;
}

const Layout = ({ children, title, containerClassName, isScrollable = true, headerExtraContent }: Props) => {
  const popupData = useAppSelector((state: RootState) => state.popup);
  const loading = useAppSelector(state => state.loader.isLoading);
  const { isOpen, showAsSheet } = useAI();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (preventAutoCloseRoutes.includes(window.location.pathname)) return;
    dispatch(setIsCollapsed(true));
  }, []);
  if (loading)
    return (
      <main className={MAIN_CLASSNAME}>
        <SpinnerLoader />
      </main>
    );

  return (
    <>
      <main className={cn(MAIN_CLASSNAME, { "blur-sm": popupData?.isOpen })}>
        <NavBar />
        <div className='flex h-full w-full overflow-hidden relative'>
          <div className='flex flex-col h-full w-full overflow-hidden'>
            <Header title={title} extraContent={headerExtraContent} />
            <div
              className={cn(
                "w-full h-full",
                isScrollable == true
                  ? "overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-600"
                  : "overflow-hidden",
                containerClassName,
              )}
            >
              {children}
            </div>
          </div>
          {isOpen && <AIPanel asSheet={showAsSheet} />}
        </div>
      </main>
      {popupData.isOpen && <Popup open={popupData.isOpen} content={popupData.content} />}
    </>
  );
};

export const DashboardLayout = ({ ...props }: Props) => {
  return (
    <AIPanelProvider>
      <Layout {...props} />
    </AIPanelProvider>
  );
};
