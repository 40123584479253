import React from "react";

export default function DotsIcon({ color, className }: any) {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill={color} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5 10.7637C3.9 10.7637 3 11.6637 3 12.7637C3 13.8637 3.9 14.7637 5 14.7637C6.1 14.7637 7 13.8637 7 12.7637C7 11.6637 6.1 10.7637 5 10.7637Z'
        fill={color}
      />
      <path
        d='M5 10.7637C3.9 10.7637 3 11.6637 3 12.7637C3 13.8637 3.9 14.7637 5 14.7637C6.1 14.7637 7 13.8637 7 12.7637C7 11.6637 6.1 10.7637 5 10.7637Z'
        fill={color}
        fillOpacity='0.2'
      />
      <path
        d='M5 10.7637C3.9 10.7637 3 11.6637 3 12.7637C3 13.8637 3.9 14.7637 5 14.7637C6.1 14.7637 7 13.8637 7 12.7637C7 11.6637 6.1 10.7637 5 10.7637Z'
        fill={color}
        fillOpacity='0.2'
      />
      <path
        d='M5 10.7637C3.9 10.7637 3 11.6637 3 12.7637C3 13.8637 3.9 14.7637 5 14.7637C6.1 14.7637 7 13.8637 7 12.7637C7 11.6637 6.1 10.7637 5 10.7637Z'
        fill={color}
        fillOpacity='0.2'
      />
      <path
        d='M5 10.7637C3.9 10.7637 3 11.6637 3 12.7637C3 13.8637 3.9 14.7637 5 14.7637C6.1 14.7637 7 13.8637 7 12.7637C7 11.6637 6.1 10.7637 5 10.7637Z'
        fill={color}
        fillOpacity='0.2'
      />
      <path
        d='M5 10.7637C3.9 10.7637 3 11.6637 3 12.7637C3 13.8637 3.9 14.7637 5 14.7637C6.1 14.7637 7 13.8637 7 12.7637C7 11.6637 6.1 10.7637 5 10.7637Z'
        fill={color}
        fillOpacity='0.2'
      />
      <path
        d='M5 10.7637C3.9 10.7637 3 11.6637 3 12.7637C3 13.8637 3.9 14.7637 5 14.7637C6.1 14.7637 7 13.8637 7 12.7637C7 11.6637 6.1 10.7637 5 10.7637Z'
        fill={color}
        fillOpacity='0.2'
      />
      <path
        d='M19 10.7637C17.9 10.7637 17 11.6637 17 12.7637C17 13.8637 17.9 14.7637 19 14.7637C20.1 14.7637 21 13.8637 21 12.7637C21 11.6637 20.1 10.7637 19 10.7637Z'
        fill={color}
      />
      <path
        d='M19 10.7637C17.9 10.7637 17 11.6637 17 12.7637C17 13.8637 17.9 14.7637 19 14.7637C20.1 14.7637 21 13.8637 21 12.7637C21 11.6637 20.1 10.7637 19 10.7637Z'
        fill={color}
        fillOpacity='0.2'
      />
      <path
        d='M19 10.7637C17.9 10.7637 17 11.6637 17 12.7637C17 13.8637 17.9 14.7637 19 14.7637C20.1 14.7637 21 13.8637 21 12.7637C21 11.6637 20.1 10.7637 19 10.7637Z'
        fill={color}
        fillOpacity='0.2'
      />
      <path
        d='M19 10.7637C17.9 10.7637 17 11.6637 17 12.7637C17 13.8637 17.9 14.7637 19 14.7637C20.1 14.7637 21 13.8637 21 12.7637C21 11.6637 20.1 10.7637 19 10.7637Z'
        fill={color}
        fillOpacity='0.2'
      />
      <path
        d='M19 10.7637C17.9 10.7637 17 11.6637 17 12.7637C17 13.8637 17.9 14.7637 19 14.7637C20.1 14.7637 21 13.8637 21 12.7637C21 11.6637 20.1 10.7637 19 10.7637Z'
        fill={color}
        fillOpacity='0.2'
      />
      <path
        d='M19 10.7637C17.9 10.7637 17 11.6637 17 12.7637C17 13.8637 17.9 14.7637 19 14.7637C20.1 14.7637 21 13.8637 21 12.7637C21 11.6637 20.1 10.7637 19 10.7637Z'
        fill={color}
        fillOpacity='0.2'
      />
      <path
        d='M19 10.7637C17.9 10.7637 17 11.6637 17 12.7637C17 13.8637 17.9 14.7637 19 14.7637C20.1 14.7637 21 13.8637 21 12.7637C21 11.6637 20.1 10.7637 19 10.7637Z'
        fill={color}
        fillOpacity='0.2'
      />
      <path
        d='M12 10.7637C10.9 10.7637 10 11.6637 10 12.7637C10 13.8637 10.9 14.7637 12 14.7637C13.1 14.7637 14 13.8637 14 12.7637C14 11.6637 13.1 10.7637 12 10.7637Z'
        fill={color}
      />
      <path
        d='M12 10.7637C10.9 10.7637 10 11.6637 10 12.7637C10 13.8637 10.9 14.7637 12 14.7637C13.1 14.7637 14 13.8637 14 12.7637C14 11.6637 13.1 10.7637 12 10.7637Z'
        fill={color}
        fillOpacity='0.2'
      />
      <path
        d='M12 10.7637C10.9 10.7637 10 11.6637 10 12.7637C10 13.8637 10.9 14.7637 12 14.7637C13.1 14.7637 14 13.8637 14 12.7637C14 11.6637 13.1 10.7637 12 10.7637Z'
        fill={color}
        fillOpacity='0.2'
      />
      <path
        d='M12 10.7637C10.9 10.7637 10 11.6637 10 12.7637C10 13.8637 10.9 14.7637 12 14.7637C13.1 14.7637 14 13.8637 14 12.7637C14 11.6637 13.1 10.7637 12 10.7637Z'
        fill={color}
        fillOpacity='0.2'
      />
      <path
        d='M12 10.7637C10.9 10.7637 10 11.6637 10 12.7637C10 13.8637 10.9 14.7637 12 14.7637C13.1 14.7637 14 13.8637 14 12.7637C14 11.6637 13.1 10.7637 12 10.7637Z'
        fill={color}
        fillOpacity='0.2'
      />
      <path
        d='M12 10.7637C10.9 10.7637 10 11.6637 10 12.7637C10 13.8637 10.9 14.7637 12 14.7637C13.1 14.7637 14 13.8637 14 12.7637C14 11.6637 13.1 10.7637 12 10.7637Z'
        fill={color}
        fillOpacity='0.2'
      />
      <path
        d='M12 10.7637C10.9 10.7637 10 11.6637 10 12.7637C10 13.8637 10.9 14.7637 12 14.7637C13.1 14.7637 14 13.8637 14 12.7637C14 11.6637 13.1 10.7637 12 10.7637Z'
        fill={color}
        fillOpacity='0.2'
      />
    </svg>
  );
}
