import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { TaskItem } from "api/task/type";

import { ROUTES } from "constants/routes.constants";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";

import { getTasksList } from "storage/actions/task";

import { Breadcrumbs } from "shared/components/breadcrumbs/Breadcrumbs";
import { Input } from "shared/components/form-elements/Input";
import { Icons } from "shared/components/icons";
import { DashboardLayout } from "shared/layouts/DashboardLayout";

import { cn } from "lib/utils";

export default function Projects() {
  const dispatch = useAppDispatch();

  const [filter, setFilter] = useState<"all" | "task" | "workflow" | "created">("all");
  const [search, setSearch] = useState<string>("");
  const [showSearchInput, setShowSearchInput] = useState<boolean>(false);

  const taskList = useAppSelector(state => state.task.taskList);

  const tasks = useMemo(() => {
    if (!taskList) return [];

    let data = taskList;

    if (search !== "") {
      data = data.filter(task => task.task_name.toLowerCase().includes(search.toLowerCase()));
    }

    // if (filter !== "all") {
    //   data = data.filter(task => {
    //     if (filter === "task") return task.agent_id !== null;
    //     if (filter === "workflow") return task.workflow_id !== null;
    //     if (filter === "created") return task.agent_id === null && task.workflow_id === null;
    //     return true;
    //   });
    // }

    return data;
  }, [taskList, filter, search]);

  const handleShowSearchInput = () => {
    setShowSearchInput(!showSearchInput);
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    dispatch(getTasksList({ page: 1 }));
  }, []);

  return (
    <DashboardLayout title='Browse templates' containerClassName='bg-white'>
      <div className='px-6 pt-6 pb-10'>
        <Breadcrumbs
          breadcrumbs={[
            {
              path: "/",
              label: "Home",
              active: false,
            },
            {
              path: "",
              label: "Browse templates",
              active: true,
            },
          ]}
        />
        <div className='mt-2 flex flex-col mb-4'>
          <h1 className='text-2xl text-[#333740] font-semibold'>Browse all templates</h1>
          <div className='flex mt-6 divide-x divide-[#E9EAEC]'>
            <div className='flex items-center gap-x-2 pr-4'>
              <FilterBtn label='All' active={filter === "all"} value='all' onClick={setFilter} />
              <FilterBtn label='Tasks' active={filter === "task"} value='task' onClick={setFilter} />
              <FilterBtn label='Workflows' active={filter === "workflow"} value='workflow' onClick={setFilter} />
              <FilterBtn label='Created' active={filter === "created"} value='created' onClick={setFilter} />
            </div>
            <div className='pl-4 flex gap-1'>
              {showSearchInput && (
                <Input
                  onChange={handleSearchInputChange}
                  placeholder='Search templates'
                  maxLength={50}
                  containerClassName='flex flex-col gap-1'
                  className='!rounded-[24px]'
                />
              )}
              <button
                onClick={handleShowSearchInput}
                className='border border-[#E9EAEC] p-2.5 rounded-[24px] text-sm font-medium text-[#717684]'
              >
                <Icons.Search02 className='' />
              </button>
            </div>
          </div>
        </div>
        <div className='grid grid-cols-1 gap-6 md:grid-cols-3 md:gap-4 '>
          {tasks.length > 0 ? tasks.map((task, index) => <TaskCard key={index} task={task} />) : <div></div>}
        </div>
      </div>
    </DashboardLayout>
  );
}

const FilterBtn = ({
  label,
  active,
  onClick,
  value,
}: {
  label: string;
  active: boolean;
  value: "all" | "task" | "workflow" | "created";
  onClick: (value: "all" | "task" | "workflow" | "created") => void;
}) => {
  return (
    <button
      onClick={() => onClick(value)}
      className={cn(" px-5 py-2.5 rounded-[24px] text-sm font-medium", active ? "bg-[#F7F7F8]" : "border border-[#E9EAEC] text-[#717684]")}
    >
      {label}
    </button>
  );
};

const TaskCard = ({ task }: { task: TaskItem }) => {
  const platform = task.workflow?.additional_fields?.seo_platform || task.workflow?.additional_fields?.social_platform;

  return (
    <Link to={ROUTES.budgetForm + "?task_id=" + task.id} className='bg-[#F7F7F8] border border-[#E9EAEC] rounded-[1.25rem] p-6 gap-x-6'>
      <div className='flex flex-col gap-4'>
        <div className='flex gap-2'>
          <Icons.Calender className='w-4 h-4' />
          <p className='text-[#717684] font-medium text-xs'>Task</p>
        </div>
        <div className='flex flex-col gap-2'>
          <h1 className='font-semibold text-base text-black1'>{task.task_name}</h1>
          <p className='font-medium text-sm text-grey1'>{task.task_description}</p>
        </div>
        <div className='flex justify-start'>
          {platform && (
            <>
              {platform.split(",").map((item, index) => {
                let icon: React.ReactNode | null = null;
                const iconName = item.toLowerCase();

                if (iconName === "facebook") {
                  icon = <Icons.Facebook />;
                } else if (iconName === "instagram") {
                  icon = <Icons.Instagram />;
                } else if (iconName.includes("tiktok")) {
                  icon = <Icons.TikTok />;
                } else if (iconName.includes("google")) {
                  icon = <Icons.GoogleAds />;
                } else if (iconName.includes("twitter")) {
                  icon = <Icons.TwitterOrX />;
                } else if (iconName.includes("bing")) {
                  icon = <Icons.BingAds />;
                } else if (iconName.includes("linkedin")) {
                  icon = <Icons.LinkedIn />;
                } else if (iconName.includes("email")) {
                  icon = <Icons.Email />;
                } else if (iconName.includes("pinterest")) {
                  icon = <Icons.Pinterest />;
                }

                return (
                  <span
                    key={index}
                    className='inline-flex items-center gap-1 border border-[#E9EAEC] text-xs font-semibold text-[#464A53] bg-white py-2 px-3 rounded-full'
                  >
                    {icon && icon}
                    {item}
                  </span>
                );
              })}
            </>
          )}
        </div>
      </div>
    </Link>
  );
};
