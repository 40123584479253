import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import dayjs from "dayjs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { pauseConnection, refreshConnection, removeConnection } from "api/integrations";

import { FULL_DATE_FORMAT_WITH_COMMA } from "constants/date-formats.constants";
import { INTEGRATION_ICONS } from "constants/integrations.constants";
import { ROUTES } from "constants/routes.constants";
import { DropdownVariants } from "constants/shared/dropdown.constants";

import { NumberOrNull } from "interfaces/shared-types.interfaces";

import { removeIdFromRoutes } from "utils/url.utils";

import { useAppDispatch } from "hooks/appHooks";

import { getAllConnectedSources } from "storage/slices/integrations-slice";

import { Dropdown } from "shared/components/dropdown/Dropdown";
import DotsIcon from "shared/components/IconsComponents/DotsIcon";
import { Table } from "shared/components/table/Table";
import Alert from "shared/components/toasts";

import { ReactComponent as PauseIcon } from "assets/icons/pause-circle.svg";
import { ReactComponent as RedDotIcon } from "assets/icons/redDotRadiate.svg";
import { ReactComponent as RefreshIcon } from "assets/icons/refreshblack.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trashred.svg";

import ConfirmOverlay from "./ConfirmOverlay";

interface Props {
  data: any[];
  selectedId: NumberOrNull;
}


export const ConnectedTable = ({ data, selectedId }: Props) => {
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [toRemoveIntegrationData, setToRemoveIntegrationData] = useState<any>(null);
  const [loadingId,setLoadingId] = useState<NumberOrNull>(null)
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dropdownOptions  = (data :any)=> ([{ id: "refreshdata", label: "Refresh Data", action: ()=>{
    handleRefresh(data)
  },icon:RefreshIcon},{ id: "pauseimport", label: data?.enabled? "Pause import":"Resume import", action: ()=>{
    pauseInport(data)
  },icon:PauseIcon},{ id: "removeintegration", label: "Remove integration", action: ()=>{
    setShowConfirmModal(true)
    setToRemoveIntegrationData(data)
  },icon:TrashIcon, textWrapperclassName:"!text-[#E36C74] !pt-2 !border-t-[1px] !border-[#E9EAEC] !border-t-solid"}])
  
  const handleRefresh = (data:any)=>{
    console.log("Refresh Data",data)
    const {connection_id} = data
    setLoadingId(connection_id)
      refreshConnection(connection_id).then(res=>{
        dispatch(getAllConnectedSources())
      }).finally(()=>{
        setLoadingId(null)
      })
  }
  const pauseInport = (data:any)=>{
    console.log("Pause Import",data)
    const {connection_id,enabled} = data
    setLoadingId(connection_id)
    pauseConnection(connection_id,{enabled:!enabled}).then(res=>{
        dispatch(getAllConnectedSources())
    }).finally(()=>{
      setLoadingId(null)
    })
  }
  const removeIntegration = ()=>{
    const {connection_id} = toRemoveIntegrationData
    setLoadingId(connection_id)
    removeConnection(connection_id).then(res=>{
      dispatch(getAllConnectedSources())
      setShowConfirmModal(false)
      Alert("success", "Integration removed successfully", "Success");
    }).finally(()=>{
      setLoadingId(null)
    })
  }
  const columnHelper = createColumnHelper<any>();
  const columns = [
    columnHelper.accessor("name", {
      header: "Platform Name",
      cell: info => {
        const Icon = INTEGRATION_ICONS?.[info?.row?.original?.user_data?.logo as keyof typeof INTEGRATION_ICONS];
        return <button className="flex items-center gap-x-1.5" onClick={()=>{navigate(removeIdFromRoutes(ROUTES.connectedSourcesDetails)+info?.row.original?.connection_id)}}>
          <Icon className='h-6 w-6' />
          <p>{info.renderValue()}</p>
        </button>
      },
    }),
    columnHelper.accessor("account_name", {
      header: "Account Name",
      cell: info => info.renderValue(),
    }),
    columnHelper.accessor("date_created", {
      id: "date_created",
      header: "Added",
      cell: info => dayjs(info.getValue()).format(FULL_DATE_FORMAT_WITH_COMMA),
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: info => {
        const value = info.getValue();
        return (
          loadingId === info?.row?.original?.connection_id?<div className="flex items-center gap-x-1.5">
            <div className="animate-ping h-2 w-2 border-2 rounded-full border-t-2 border-purple mr-1" />
            <p>Updating...</p>
          </div>:<div
            className={"text-xs font-semibold px-3 py-1 rounded-full w-fit flex items-center capitalize"}
            style={{ backgroundColor: value === "active" ? "#DFFFE2" : "#FFECD6", color: value === "active" ? "#02A613" : "#D17102" }}
          >
            <div className='w-1 h-1 mr-1.5 rounded-full' style={{ backgroundColor: value === "active" ? "#02A613" : "#D17102" }} />{" "}
            {value ? value : "Unknown"}
          </div>
        );
      },
    }),
    columnHelper.accessor("message", {
        header: "Message",
        cell: info => {
          const value = info.getValue();
          return (
            !value? <div className='text-sm font-normal py-1 rounded-full w-fit flex items-center capitalize text-[#9EA2AD]'>
              No new updates
            </div>:<div className='text-sm font-normal py-1 rounded-full w-fit flex items-center capitalize'>
              <RedDotIcon className='w-3 h-3 mr-1.5'/>
              You have 1 new alert
            </div>
          );
          
        }
      }),
      columnHelper.accessor("Action", {
        header: "",
        cell: info => {
            const value = info.getValue();
            return (
              <div className="flex justify-end">
              <Dropdown items={dropdownOptions(info?.row?.original)} variant={DropdownVariants.SECONDARY}>
              <button
                className={"transform rotate-90 h-6 w-6"}
              >
              <DotsIcon />
              </button>
              </Dropdown>
              </div>
            );
          }
      }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      
      <Table
        className='max-h-[234px]'
        table={table}
        idKey='connection_id'
        hover={true}
      />
      {showConfirmModal && <ConfirmOverlay onCancel={()=>{setShowConfirmModal(false)}} onClick={()=>{removeIntegration()}} title="Are you sure?" description="You want to remove this integration?" loading={loadingId ? true : false} />}
    </div>
  );
};
