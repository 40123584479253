import { ROUTES } from "constants/routes.constants";

import { StringOrNumber } from "interfaces/shared-types.interfaces";

export const openUrlInSeparateTab = (link: string) => window.open(link, "_blank", "noopener,noreferrer");

export const getPerformancePlanUrlById = (id: StringOrNumber) => ROUTES.performancePlan.replace(":id", String(id));

export const getWorkflowDetailsUrlById = (id: StringOrNumber) => ROUTES.workflowDetails.replace(":id", String(id));

export const removeIdFromRoutes= (url:string) => url.replace(":id", "");
