import { useContext, useEffect, useState } from "react";

import { getCategories } from "api/budget";

import { convertCategoriesToDesiredFormat } from "utils/budget.utils";
import { errorWithLink, showCommonServerError } from "utils/modules.utils";
import { getColumnData } from "utils/sheets.utils";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";

import { openPopup } from "storage/slices/popup-slice";

import { ReactComponent as AiStarIcon } from "assets/icons/aiStarPurple.svg";
import { ReactComponent as DragnDropIcon } from "assets/icons/dragndrop.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";

import { ConnectSourceContext } from "../ConnectDataSource";

import CategoriesDnD from "./components/Categories_DnD";


const ConfirmCategories = () => {
  const [loading ,setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const { setCategories,row, coordinates, categories,locateData, worksheetUuid, fileName } = useContext(ConnectSourceContext);
  const { channels } = useAppSelector(state => state.project?.project) || {};
  const dispatch = useAppDispatch()
  const getCategoriesList = async()=>{
    try{
      if(categories) return
      setLoading(true)
      setError(false)
      const columnData:string[] = getColumnData(row,coordinates?.yAxis,locateData?.data_range?.start.row )
      let categoriesList:any
      try{
         categoriesList = await getCategories(columnData, worksheetUuid); // TO DO: make sure this worksheetUuid is the same across all these requests related to the same set of data
      }
      catch(err){
        setError(true)
        errorWithLink(err,()=>{
          getCategoriesList()
        })
        setLoading(false)
        return
      }
      if(!categoriesList || categoriesList?.mapping?.length ===0) {
        alert("Sheet Missing Primary Data") 
        return
      }
      const convertedData = convertCategoriesToDesiredFormat(categoriesList,channels)
      console.log("Converted Categories Data:",convertedData)
      setCategories(convertedData);
      setLoading(false)
    }catch(err){
      setError(true)
      showCommonServerError(err)
      setLoading(false)
    }
  }
  useEffect(()=>{
    getCategoriesList()
  },[])
  
  return (
    <>
      <div className='px-6 py-4'>
        <div className='flex flex-col gap-6 p-6 rounded-[22px] bg-white'>
          <div className='flex flex-row gap-4 flex-wrap'>
            <div className='flex-1 min-w-96'>
              <div className=''>
                <p className='text-base font-bold text-[#2E323A]'>Confirm Combined Categories</p>
              </div>
              <div className='flex flex-row items-center'>
                <AiStarIcon className={`h-4 w-5 mr-3 ${false ? "animate-spin" : ""}`} />
                <div>
                <p className='text-sm font-medium text-[#333740] mt-4 leading-5'>
                  The AI agent has automatically grouped similar categories for better budget management.
                </p>
                <p className="text-sm font-medium text-[#333740] mt-[3px]">
                Please review these combinations and adjust if needed.
                </p>
                </div>
              </div>
            </div>
            <div className='flex-1 min-w-96 sm:text-start  md:text-end'>
            <button onClick={()=>{dispatch(openPopup("CreateBudget"))}} className=' text-sm font-semibold '>Uploaded File: <span className="text-[#766CE4] underline underline-offset-4">{fileName}</span></button>
            </div>
          </div>
          <hr className='my-5 horizontal-line' />
          {!error ?<div>
            <p className='text-base font-bold text-[#2E323A]'>Unassigned channels</p>
            <div className='flex flex-row items-center mt-4'>
              <DragnDropIcon className={"h-6 w-6 mr-3"} />
              <p className='text-sm font-medium text-[#333740]'>
                Drag and drop these channels to the appropriate categories below. They wont appear in your budget until they are placed in one of
                the seven main categories.
              </p>
            </div>
          </div>:<div>
          <p className='text-base font-bold text-[#2E323A]'>Something went wrong! Things you need to check.</p>
          <div className='items-center mt-2'>
              <p className='text-sm font-medium text-[#333740] mt-1 ml-2'>
                1. Check your worksheet if it contains essential months row and relevant data with numeric budget list.
              </p>
              <p className='text-sm font-medium text-[#333740] mt-1 ml-2'>
                2. Go back and check if you have selected correct coordinates in Locate Data page.
              </p>
              <p className='text-sm font-medium text-[#333740] mt-1 ml-2'>
                3. Check your internet connection.
              </p>
            </div>
            <div>
              <button className="p-4 rounded-xl bg-purpleP0 mt-4" onClick={()=>{getCategoriesList()}}> 
                <p className="text-sm font-medium">Click here to try again</p>
              </button>
            </div>
          </div>}
          
          {loading && <div className="grid grid-cols-4 gap-6">
          {[1,2,3,4,5,6,7].map(item=><div key={item} className="border border-blue-300 shadow rounded-md max-w-sm w-full mx-auto overflow-hidden">
            <div className="animate-pulse">
              
              <div className=" bg-slate-200 h-4 w-full"></div>
              <div className="flex items-center px-3 py-1"> 
              <div className=" bg-slate-200 h-6 w-6 rounded-md mr-3"></div>
              <div className=" bg-slate-200 h-5 w-16"></div>
              </div>
              <hr className="w-full"/>
              <div className="flex mt-3 px-3">
              <div className=" bg-slate-200 h-6 w-24 rounded-md mr-5"></div>
              <div className=" bg-slate-200 h-6 w-20 rounded-md mr-5"></div>
              
             </div>
             <div className="flex">
             <div className=" bg-slate-200 h-6 w-40 rounded-md mr-5 my-3 mx-3"></div>
              </div>
            </div>
          </div>)}
          </div>}
          {!loading && <CategoriesDnD error={error} />}
        </div>
      </div>
    </>
  );
};

export default ConfirmCategories;
