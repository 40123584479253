import { HTMLProps, useEffect, useRef } from "react";

export function CheckboxPurple({
  checked,
  className = "",
  ...rest
}: { checked?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof checked === "boolean") {
      ref.current.checked =  checked;
    }
  }, [ref, checked]);

  return (
    <div className='relative flex items-center'>
      <input
        type='checkbox'
        ref={ref}
        checked={checked}
        className={`
            peer
            appearance-none cursor-pointer
            h-[18px] w-[18px] rounded
            border border-[#E9EAEC]
            checked:bg-[#766CE4] checked:border-[#766CE4]
            focus:outline-none focus:ring-2 focus:ring-[#766CE4] focus:ring-opacity-50
            ${className}
          `}
        {...rest}
      />
      <svg
        className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -mt-0.5 -translate-y-1/3 w-3 h-3 pointer-events-none hidden peer-checked:block'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        fill='none'
        stroke='white'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <polyline points='20 6 9 17 4 12'></polyline>
      </svg>
    </div>
  );
}
