import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ICreateProjectData, IProjectData } from "../../api/projects/types";
import { ProjectListItem } from "../../interfaces/projects.interfaces";
import { getProjectsListReducer } from "../reducers/projects";

interface PaginationMetadata {
  page: number;
  totalPages: number;
  totalItems: number;
}
export interface ProjectState {
  project: IProjectData | null;
  projectList: ProjectListItem[];
  newProjectForm: Partial<ICreateProjectData> & {
    isRevenueUnknown?: boolean;
  };
  pagination: PaginationMetadata;
  method: "upload" | "manual";
  uploadedProject: IProjectData | null;
  fileAiProcess: boolean;
  parsedData?: any;
  loading: boolean;
}

const initialState: ProjectState = {
  project: null,
  projectList: [],
  newProjectForm: {},
  uploadedProject: null,
  method: "manual",
  pagination: {
    page: 1,
    totalPages: 0,
    totalItems: 0,
  },
  fileAiProcess: false,
  loading: false,
  parsedData: [],
};

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setPoject: (state, action: PayloadAction<IProjectData | null>) => {
      state.project = action.payload;
    },
    updateLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setUploadedPoject: (state, action: PayloadAction<IProjectData | null>) => {
      state.uploadedProject = action.payload;
    },
    handleProjectForm: (state, action: PayloadAction<Partial<ICreateProjectData> & { isRevenueUnknown?: boolean }>) => {
      state.newProjectForm = action.payload;
    },
    changeMethod: (state, action: PayloadAction<"upload" | "manual">) => {
      state.method = action.payload;
    },

    setParsedData: (state, action: PayloadAction<any>) => {
      state.parsedData = action.payload;
    },
  },
  extraReducers: builder => {
    getProjectsListReducer(builder);
  },
});

export const { setPoject, handleProjectForm, changeMethod, setUploadedPoject, setParsedData, updateLoader } = projectSlice.actions;
export const projectReducer = projectSlice.reducer;
