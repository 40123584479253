import { Meta } from "interfaces/meta.interfaces";
import { StringOrNumber } from "interfaces/shared-types.interfaces";
import {

} from "interfaces/integrations.interfaces";


import api from "..";

const BASE_API = process.env.REACT_APP_API_URL;

export const getConnectSourceList = (): Promise<any[]> => {
  return api(`${BASE_API}/integrations`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};

export const getConnectedSourceList = (): Promise<{data:any[]}> => {
    return api(`${BASE_API}/connections`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
  };

export const generateCredentialForAppIntegration = (id: number): Promise<any> => {
  return api(`${BASE_API}/credentials/auth-url/${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};

export const saveOauth2Credential = (code: string, state: string): Promise<any> => {
  return api(`${BASE_API}/credentials/oauth-callback?code=${code}&state=${encodeURIComponent(state)}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};
export const createConnection = (data: any): Promise<any> => {
  return api(`${BASE_API}/connections`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
};

export const refreshConnection = (id: StringOrNumber): Promise<any> => {
  return api(`${BASE_API}/connections/${id}/sync`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });
};

export const pauseConnection = (id: StringOrNumber,data:{enabled?:boolean,account_name?:string}): Promise<any> => {
  return api(`${BASE_API}/connections/${id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
};

export const removeConnection = (id: StringOrNumber): Promise<any> => {
  return api(`${BASE_API}/connections/${id}`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
  });
};

export const getConnectedData = (id: StringOrNumber)=>{
  return api(`${BASE_API}/connections/${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
}