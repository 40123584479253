import cn from "classnames";
import { useCallback, useContext, useEffect, useState } from "react";

import { Dropdown } from "shared/components/dropdown-checkbox/Dropdown-Checkbox";

import { ReactComponent as BriefCaseIcon } from "assets/icons/briefcase-2.svg";
import { ReactComponent as TickIcon } from "assets/icons/check-white.svg";
import { ReactComponent as CrossIcon } from "assets/icons/cross-black.svg";
import { ReactComponent as CompanyProfileIcon } from "assets/icons/editIcon.svg";

import { SetupCompanyProfileContext } from "../SetupCompanyProfile";

import { CompanyToneLoader } from "./loadingComponents";

export type IndustryDropdownType = {
    id:string,
    label:string
}
const CompanyBrandTone = () => {
  const [editable, setEditable] = useState(false);
  const [selectedData, setSelectedData] = useState<string[]>([])
  const [dropdownOptions, setDropdownOptions] = useState<IndustryDropdownType[]>([]);
  const {data, loading, apiData, updateApiData} = useContext(SetupCompanyProfileContext)
  const handleTick = () => {
    updateApiData({ ...apiData, brandtone: selectedData });
    setEditable(false);
  };
  const handleCrossClick = () => {
    setSelectedData(apiData.brandtone);
    setEditable(false);
  };
  const updateSelectedData = useCallback((id:string,isChecked:boolean)=>{
    if(selectedData?.length === 4 && isChecked){
      return
    }
    setSelectedData((prev)=>{
        if(isChecked){
          return [...prev,id]
        }else{
          return prev.filter(item=>item!==id)
        }
    })
  },[selectedData])
  const addDropdownOptions = useCallback((newOption:IndustryDropdownType) => {
    setDropdownOptions((prev)=>{
      if(prev.find(item=>item.id === newOption.id)){
        return prev
      }
      return [...prev,newOption]
    })
  },[])
  useEffect(()=>{
    if(apiData.brandtone){
      setSelectedData(apiData.brandtone)
    }   
  },[apiData.brandtone])
  useEffect(()=>{
    if(data){
      setDropdownOptions(data.brand_tone.map((item:string)=>({id:item,label:item})))
    }
  },[data])
  return (
    <div className='p-6 bg-white rounded-2xl col-span-4'>
      <div className='flex gap-x-4'>
        <div>
          <BriefCaseIcon />
        </div>
        <div className='w-full'>
          <div className='flex justify-between'>
            <p className='text-base font-bold text-[#2E323A]'>Company brand tone</p>
            <div className='flex items-center gap-x-2'>
            {!editable ? (
              <button className={cn("p-[7px] rounded-full border border-[#E9EAEC]",{"opacity-30":dropdownOptions?.length === 0})} disabled={dropdownOptions?.length === 0} onClick={() => setEditable(true)}>
                <CompanyProfileIcon className='h-[18px] w-[18px]' />
              </button>
            ) : (
              <>
                <button className='p-[7px] rounded-full border border-[#E9EAEC]' onClick={handleCrossClick}>
                  <CrossIcon className='h-[18px] w-[18px]' />
                </button>
                <button className='p-[7px] rounded-full border border-[#E9EAEC] bg-purple' onClick={handleTick}>
                  <TickIcon className='h-[18px] w-[18px]' />
                </button>
              </>
            )}
            </div>
          </div>
          {!loading ?<div className="relative">
          <Dropdown items={dropdownOptions} enabled={editable} selectedData={selectedData} updateSelectedData={updateSelectedData} addDropdownOptions={addDropdownOptions} >
          <div className='mt-2 border border-[#E9EAEC] rounded-xl'>
            <div className='px-1 py-1 w-full flex items-center'>
              <div className='flex gap-x-2 w-full'>
                {selectedData.map(item => (
                  <p key={item} className='bg-[#F1F0FC] rounded-md px-2 py-1.5 text-xs font-medium text-[#14191A]'>
                    {item}
                  </p>
                ))}
                {
                    selectedData.length < 1 && <p className=' rounded-md px-2 py-1.5 text-xs font-medium text-[#14191A50]'>
                    Add brand tone
                  </p>
                }
              </div>
              <p className='mx-3 text-xs font-medium text-[#14191A] py-1.5'>{selectedData?.length}/4</p>
            </div>
          </div>
          </Dropdown>
          
          </div>:<div className="mt-2">
            <CompanyToneLoader />
            </div>}
        </div>
      </div>
    </div>
  );
};
export default CompanyBrandTone;
