import cn from "classnames";
import React from "react";
import { twMerge } from "tailwind-merge";

import { ButtonVariants } from "constants/shared/button.constants";
import { ColoredLabelVariants } from "constants/shared/colored-label.constants";
import { TypographyVariants } from "constants/shared/typography.constants";
import { UserPlans } from "constants/user.constants";

import { MembershipPlan, MembershipPlanDetails } from "interfaces/memberships.interfaces";

import { getMembershipPlanMonthlyPrice } from "utils/memberships.utils";

import { useAppDispatch } from "hooks/appHooks";

import { getPaymentUrl } from "storage/actions/payments";

import { Button } from "shared/components/buttons/Button";
import { ColoredLabel } from "shared/components/ColoredLabel";
import { Typography } from "shared/components/Typography";

import { MembershipAdvantagesList } from "./memberships-advantages/MembershipAdvantagesList";

interface Props extends MembershipPlan, MembershipPlanDetails {
  isActive?: boolean;
  isYearly?: boolean;
  isMembershipPlanExists?: boolean;
  newPrice?: string;
  isSimplified?: boolean;
}

export const MembershipCard = ({
  isActive,
  isYearly,
  description,
  priceLabel,
  advantages,
  isMembershipPlanExists,
  name_public: planName,
  name_tech: planTechName,
  price_monthly_new: priceMonthly,
  price_yearly_new: priceYearly,
  price_monthly_old: oldPriceMonthly,
  price_yearly_old: oldPriceYearly,
  talk_to_sales: talkToSales,
  icon: Icon,
  monthly_price_id: monthlyPriceId,
  yearly_price_id: yearlyPriceId,
  showMostValuable,
  labelContent,
  buttonContent,
  isSimplified,
}: Props) => {
  const dispatch = useAppDispatch();
  const planPrice = getMembershipPlanMonthlyPrice({ priceYearly, priceMonthly, isYearly, talkToSales });
  const oldPrice = getMembershipPlanMonthlyPrice({ priceYearly: oldPriceYearly, priceMonthly: oldPriceMonthly, isYearly, ignoreNull: true });
  const isFreeDisabled = planTechName === UserPlans.FREE && isMembershipPlanExists;

  const isActivePayButton = Boolean(isYearly ? yearlyPriceId : monthlyPriceId) && !isFreeDisabled;

  const isCardDisabled = !isActivePayButton || isFreeDisabled;

  const handleClickPaymentButton = () => {
    const priceId = isYearly ? yearlyPriceId : monthlyPriceId;

    dispatch(getPaymentUrl({ priceId }))
      .unwrap()
      .then(({ url }) => window.open(url, "_self"));
  };
  return (
    <div
      className={twMerge(
        cn("flex flex-col min-w-80 basis-96 overflow-hidden relative", {
          "pt-0 xl:pt-0": showMostValuable,
        }),
      )}
    >
      {showMostValuable && (
        <div className='bg-[#766CE4] py-1.5 px-[11px] rounded-t-[26px] border-2 border-[#766CE4]'>
          <Typography className='uppercase text-white text-center' variant={TypographyVariants.LG_SEMIBOLD}>
            Most valuable
          </Typography>
        </div>
      )}
      <div
        className={twMerge(
          cn("px-6 xl:px-9 rounded-[28px] bg-[#FCFCFC] border border-[#D5D1F7] py-6 xl:py-9 grid grid-rows-[auto_1fr_1fr_auto]", {
            "border-2 border-[#766CE4]": isActive,
            "border-t-0 rounded-t-none xl:pt-[26px]": showMostValuable,
            "p-5 xl:p-5 xl:[&_ul]:space-y-2": isSimplified,
            "bg-[#F7F7F8] [&_p]:text-[#5E636E] [&_h6]:text-[#5E636E] [&_h5]:text-[#5E636E]": isCardDisabled,
          }),
        )}
      >
        <div>
          <div className='flex justify-between items-center'>
            <div className='w-full flex justify-between items-center gap-x-2'>
              <div className='flex items-center'>
                <div className='flex justify-center items-center w-12 h-12 bg-[#F1F0FC] rounded-2xl'>
                  <Icon className='text-[#9189E9]' />
                </div>
                <Typography className='ml-3 text-[#292447]' variant={TypographyVariants.H5}>
                  {planName}
                </Typography>
              </div>
              {(labelContent && isYearly) && <ColoredLabel variant={ColoredLabelVariants.BORDERED} color='#02A613' content={labelContent} />}
            </div>
          </div>
          <div
            className={twMerge(
              cn(" mt-5 mb-4 text-lg font-medium text-[#292447] font-plusJakartaSans", {
                "flex-col items-start mt-3 gap-y-1.5": oldPrice,
                "flex items-center": planName !== "Starter"
              }),
            )}
          >
            <div className='flex items-center'>
              {oldPrice && (
                <Typography
                  className={cn("mr-2", { "line-through font-semibold text-[#717684] mr-1.5": oldPrice })}
                  tag='p'
                  variant={oldPrice ? TypographyVariants.H2 : TypographyVariants.H1}
                >
                  {oldPrice}
                </Typography>
              )}
              <Typography className='text-[#292447] mr-2' tag='strong' variant={TypographyVariants.H1}>
                {planPrice}
              </Typography>
            </div>
            <Typography className='font-medium leading-[28px] text-[#292447]' variant={TypographyVariants.H6}>
              {priceLabel(isYearly)}
            </Typography>
          </div>
        </div>
        <Typography className='text-[#292447] text-[15px] leading-default' variant={TypographyVariants.LG_MEDIUM}>
          {description}
        </Typography>
        <Button
          className='mt-4 max-h-[40px]'
          variant={isActive ? ButtonVariants.MEDIUM_PREMIUM : ButtonVariants.LARGE_STROKED}
          onClick={handleClickPaymentButton}
          disabled={!isActivePayButton || isFreeDisabled}
        >
          {buttonContent || "Start 7-day free trial"}
        </Button>

        <MembershipAdvantagesList advantagesList={advantages} />
      </div>
    </div>
  );
};
