import { Meta } from "interfaces/meta.interfaces";
import { StringOrNumber } from "interfaces/shared-types.interfaces";
import {
  CreateWorkflowPayload,
  GenerateWorkflowPayload,
  Strategy,
  Workflow,
  WorkflowListItem,
  WorkflowStrategy,
} from "interfaces/workflows.interfaces";

import { convertMetaToQueryParams } from "utils/meta.utils";

import api from "..";

const BASE_API = process.env.REACT_APP_API_URL;

export const getWorkflowsStrategy = ({ id }: { id: number }): Promise<WorkflowStrategy[]> => {
  return api(`${BASE_API}/workflows/strategy/${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};
export const getWorkflowsStrategies = (): Promise<Strategy[]> => {
  return api(`${BASE_API}/workflows/strategy/all`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};

export const apiGetWorkflow = (id: StringOrNumber): Promise<Workflow> => {
  return api(`${BASE_API}/workflows/${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};

export const apiCreateWorkflow = (payload: CreateWorkflowPayload): Promise<Workflow> => {
  return api(`${BASE_API}/workflows`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload),
  });
};

export const apiGenerateWorkflow = ({ additional_fields, workflow_id, file_id }: GenerateWorkflowPayload): Promise<Workflow> => {
  return api(`${BASE_API}/workflows/${workflow_id}`, {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ additional_fields, ...file_id && {file_id:file_id} }),
  });
};

export const apiGetAllWorkflows = (payload?: Meta): Promise<WorkflowListItem[]> => {
  return api(`${BASE_API}/workflows?${convertMetaToQueryParams(payload)}`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
};

export const updateWorkflow = (id: number, data: object): Promise<Workflow> => {
  return api(`${BASE_API}/workflows/${id}`, {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
};
