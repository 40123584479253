import cn from "classnames";
import { useCallback, useMemo } from "react";
import { Tooltip } from "react-tooltip";

import { TypographyVariants } from "constants/shared/typography.constants";

import { useAppSelector } from "hooks/appHooks";

import { Icons } from "shared/components/icons";
import { Typography } from "shared/components/Typography";

import { BudgetSuggestionsSlider } from "page-components/budgets/budget-suggestions-slider/BudgetSuggestionsSlider";

import { ReactComponent as BigInfoIcon } from "assets/icons/bigInfo.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import { ReactComponent as SparcleIcon } from "assets/icons/sparcles.svg";

import LoadingOverlay from "../../components/LoadingOverlay";
import { RevenueFormattedValue } from "../RevenueFormattedValue";

import classes from "./BrainvoySuggest.module.scss";

interface QuarterlyRoadmapItem {
  quarter_number: number;
  quarter_year: number;
  revenue_impact: number;
  confidence_score: number;
  explanation: string;
}

const BrainvoySuggest = () => {
  const suggestions = useAppSelector(state => state.project.project?.project_data.suggestions) || [];
  const quarterlyRoadmap =
    useAppSelector(state => state.project.project?.project_data?.quarterly_roadmap) || ([] as QuarterlyRoadmapItem[]);
  const project = useAppSelector(state => state.project.project);

  const { revenue_from, revenue_to } = project || {};

  const totalRevenue = quarterlyRoadmap.reduce((acc: number, item: any) => {
    acc += item?.revenue_impact;
    return acc;
  }, 0);

  function formatRevenue(revenue: any) {
    if (revenue < 1000) {
      return revenue?.toString();
    } else if (revenue < 1000000) {
      return (revenue / 1000).toFixed(0) + "K";
    } else {
      return (revenue / 1000000).toFixed(0) + "M";
    }
  }

  const formattedSuggestions = useMemo(() => {
    const chunkSize = 3;

    if (Array.isArray(suggestions)) {
      return suggestions.reduce((acc, _, index) => {
        if (index % chunkSize === 0) {
          acc.push(suggestions.slice(index, index + chunkSize));
        }
        return acc;
      }, []);
    } else {
      return suggestions;
    }
  }, [suggestions]);
  const { loading } = useAppSelector(state => state.project);

  // const loading = false; // Temp - TODO: Connnect it with a real state
  const text =
    "Our AI is analyzing your data to provide tailored insights and recommendations. We're evaluating market trends, competitive landscapes, and your business parameters to optimize strategies and budget allocations. This process ensures accurate, relevant suggestions for your success. Thank you for your patience as we prepare your personalized business roadmap.";
  return (
    <section className={"my-6 grid grid-cols-6"}>
      <div className={"col-span-4 flex-col flex gap-2"}>
        <div className={classes.topSection__suggest__header}>
          <button>
            <SparcleIcon className='outline-none focus:outline-none' />
          </button>
          <Typography className='text-black text-[20px] font-bold flex gap-1 items-center' variant={TypographyVariants.H5}>
            Brainvoy Suggest
          </Typography>
        </div>
        <div className={cn(classes.topSection__suggest__list)}>
          <BudgetSuggestionsSlider
            suggestions={
              loading ? { competitive_outlook: [text], budget_recommendation: ["1"], competitor_insights: ["2"] } : formattedSuggestions
            }
          />
        </div>
      </div>

      <div className={"col-span-2"}>
        <Tooltip
          id='title-tooltip'
          positionStrategy='fixed'
          place='top'
          opacity={1}
          style={{
            borderRadius: "13px",
            background: "#2E323A",
            boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.20)",
            zIndex: 1000,
            fontSize: "12px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>This data was generated</span>
            <span>based on AI analysis</span>
            <span>of the provided inputs.</span>
          </div>
        </Tooltip>
        <div className='flex flex-col gap-2'>
          <div className={"text-[20px] font-bold flex gap-1 items-center"}>
            Revenue Opportunity
            <BigInfoIcon data-tooltip-id='title-tooltip' className='outline-none focus:outline-none' />
          </div>
          <div className={cn("flex bg-white rounded-2xl py-4 pl-4 pr-2 gap-2 justify-between h-[205px]")}>
            <LoadingOverlay isShown={loading} />
            <div className={classes.topSection__opportunity__card__data}>
              <div className='flex flex-col gap-4 max-w-[250px]'>
                <Typography className='text-[#5E636E] !font-medium flex items-center' tag='span' variant={TypographyVariants.MD_SEMIBOLD}>
                  Estimated revenue from marketing in Q{quarterlyRoadmap[quarterlyRoadmap.length - 1]?.quarter_number}{" "}
                </Typography>
                <Typography className='text-[#000]' tag='span' variant={TypographyVariants.H7}>
                  ${formatRevenue(quarterlyRoadmap[quarterlyRoadmap.length - 1]?.revenue_impact)}
                </Typography>
              </div>
              <div className={"flex flex-col gap-1.5"}>
                <Typography className='text-[#5E636E] flex items-center !font-medium' tag='span' variant={TypographyVariants.MD_SEMIBOLD}>
                  Confidence score <InfoIcon className='outline-none focus:outline-none ml-1.5' />
                </Typography>

                <div className={classes.topSection__opportunity__card__data__total}>
                  <span className={classes.topSection__opportunity__card__data__total__rightData}>
                    {quarterlyRoadmap[quarterlyRoadmap.length - 1]?.confidence_score}%
                  </span>
                  <div
                    className={classes.topSection__opportunity__card__data__total__progress}
                    style={{
                      width: `${quarterlyRoadmap[quarterlyRoadmap.length - 1]?.confidence_score}%`,
                      background: "#D5D1F7",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            {quarterlyRoadmap[quarterlyRoadmap.length - 1] && quarterlyRoadmap[quarterlyRoadmap.length - 1].explanation && (
              <div className='max-w-[240px] bg-[#F7F7F8] rounded-2xl py-3 pl-2 pr-3 flex gap-2'>
                <div>
                  <Icons.LampChange />
                </div>
                <div className='flex flex-col gap-1'>
                  <p className='font-medium text-sm text-[#1F2228]'>{quarterlyRoadmap[quarterlyRoadmap.length - 1]?.explanation}</p>
                  <div className='flex justify-end'>
                    <button className='text-[#766CE4] font-semibold text-sm flex items-center gap-1'>
                      <span className='underline'>View more</span>
                      <Icons.ArrowTopRight className='text-[#766CE4] h-[8px] w-[8px]' />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BrainvoySuggest;
