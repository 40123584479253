import { useEffect, useState, type FC } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ROUTES } from "constants/routes.constants";

import { ctaEvent, extractUserDetails } from "utils/analytics.utils";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";

import { getWorkflowsStrategiesList } from "storage/actions/workflows";
import { getUserPreferences } from "storage/slices/preferences-slice";
import { setIsCollapsed } from "storage/slices/sidebar-slice";

import { months } from "shared/components/budget/datePicker/DatePicker";
import OperationalFakeDoor from "shared/components/popups/operationalFakeDoor/OperationalFakeDoor";
import { DashboardLayout } from "shared/layouts/DashboardLayout";

import { ReactComponent as ArrowRight } from "assets/icons/arrow-right-gray.svg";
import { ReactComponent as BriefcaseIcon } from "assets/icons/briefcase.svg";
import { ReactComponent as OperationalIcon } from "assets/icons/operational-view.svg";

import { cn } from "lib/utils";
import { SidebarsWidth } from "types";

import classes from "./BudgetIntelligence.module.scss";
import ExecutiveView from "./ExecutiveView/ExecutiveView";
import MarketingView from "./MarketingpView/MarketingView";
import OperationalView from "./OperationalView/OperationalView";

const BudgetIntelligence: FC<any> = () => {
  const { CHAT_OPENED_WIDTH, CHAT_CLOSED_WIDTH } = SidebarsWidth;
  const { budget_date_from, budget_date_to } = useAppSelector((state: any) => state.project.project || {});
  const user = useAppSelector(state => state.auth?.user);
  const { state } = useLocation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    // dispatch(setIsCollapsed(true));
    dispatch(getUserPreferences());
  }, []);

  const [isMarketingView, setIsMarketingView] = useState<boolean>(false);
  const [view, setView] = useState<string>(state?.view || "executive");
  const [openChat] = useState(false);

  const handleSwitcher = (isMarketing = true) => {
    setIsMarketingView(isMarketing);
  };
  const onEditBtnClick = () => {
    navigate(ROUTES.editBudgetForm + "/" + id);
  };
  return (
    <DashboardLayout title='Budget Intelligence' isScrollable={false}>
      <div className='flex flex-col h-full'>
        <div className='bg-white border-b border-[#F7F9FB] px-6 pt-2'>
          <div className='flex justify-between items-center'>
            <div className={"flex gap-5 items-center"}>
              <button
                className={cn(
                  "flex gap-1 items-center px-2.5 pt-1 pb-3 font-semibold text-sm",
                  view === "executive" ? "border-b-2 border-[#766CE4] text-[#766CE4]" : "text-[#717684]",
                )}
                onClick={() => setView("executive")}
              >
                <BriefcaseIcon /> Executive View
              </button>
              <button
                className={cn(
                  "flex gap-1 items-center px-2.5 pt-1 pb-3 font-semibold text-sm",
                  view === "operational" ? "border-b-2 border-[#766CE4] text-[#766CE4]" : "text-[#717684]",
                )}
                onClick={() => {
                  ctaEvent("Operational view", extractUserDetails(user));
                  setView("operational");
                }}
              >
                <OperationalIcon /> Operational View
              </button>
            </div>
            <div className='flex gap-2 pb-2.5 items-center text-sm leading-default font-medium text-[#464A53]'>
              Budget period:{" "}
              <div className='flex items-center gap-1'>
                <div className='py-2 px-[11px] bg-white rounded-lg border-[1px] h-[34px] border-[#E9EAEC] text-[#1F2228] text-sm leading-default font-medium'>
                  {months[Number(budget_date_from?.split("-")[1]) - 1]} {budget_date_from?.split("-")[0]}
                </div>
                <ArrowRight />
                <div className='py-2 px-[11px] bg-white rounded-lg border-[1px] h-[34px] border-[#E9EAEC] text-[#1F2228] text-sm leading-default font-medium'>
                  {months[Number(budget_date_to?.split("-")[1]) - 1]} {budget_date_to?.split("-")[0]}
                </div>
                <button
                  className='py-2 px-[11px] h-[34px] text-[#766CE4] underline underline-offset-3 text-sm leading-default font-semibold hover:font-bold'
                  onClick={onEditBtnClick}
                >
                  Edit budget
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={cn(
            "flex h-full w-full bg-[#F7F9FB]",
            classes.budgetIntelligence,
            view === "executive"
              ? "overflow-y-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-600"
              : "overflow-hidden",
          )}
        >
          {/* {isMarketingView ? <MarketingView openChat={openChat} /> : <ExecutiveView openChat={openChat} />} */}
          {view === "executive" ? <ExecutiveView openChat={openChat} /> : <OperationalView />}
          {/* <div
            className={`relative max-h-full  flex-grow flex flex-col gap-y-4 overflow-y-auto custom-scrollbar ${
              openChat ? "chartContainerOpenChat" : "chartContainer"
            } `}
            style={{ width: `calc(100% -${openChat ? CHAT_OPENED_WIDTH : CHAT_CLOSED_WIDTH} )` }}
          >
         
          </div> */}
          {/* <AiChatAssistant openChat={openChat} setOpenChat={setOpenChat} /> */}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default BudgetIntelligence;
