import { useEffect, useRef, useState } from "react";

import { useSocketIO } from "hooks/useSocketIO";

import TextTypingEffect from "shared/components/text-typing-effect/TextTypingEffect";

const ChatAi = () => {
  const {socket} = useSocketIO(`${process.env.REACT_APP_WS_URL}/chat`)
  const [data, setData] = useState<string[]>([]);

  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false)
  const streamingRef = useRef<boolean>(false)
  const regenerateTextRef = useRef<string>("")
  const handleSendMessage = () => {
    if (!socket) return;
    setLoading(true)

    const message = {
      data: {
        message: text,
      }
    };
    console.log("Message",message);
    socket.emit("user_input", message);
    regenerateTextRef.current = text
    setText("");
  };
  const handleRegenerate =  ()=>{
    if (!socket) return;
    setData(prev=> {
        const copy = JSON.parse(JSON.stringify(prev))
        copy.pop()
        return copy
    })
    setLoading(true)
    socket.emit("user_input", regenerateTextRef.current);
  }
  useEffect(() => {
    if (!socket) return;
    // Handle incoming messages from the server
    socket.on("stream", event => {
        setLoading(false)
        console.log("Event",event)
        const message = event.data;
      console.log("Stream",message)

      
        if(streamingRef.current === false) {
            setData(prev=> [...prev, message])
        }else{
            setData(prev => {
                const copy = JSON.parse(JSON.stringify(prev))
                if(copy.length ===0){
                  copy[0] = message
                }else{
                    copy[copy.length -1] = copy[copy.length -1] + message
                }
                
                return copy
    
            })
        }
        streamingRef.current = true
        console.log("Stream data:", message);
      
    });
    socket.on("end", () => {
        streamingRef.current = false

    });
   
  }, [socket]);
  return (
    <div className="w-1/3 h-3/4 absolute z-50 right-0 bottom-0 bg-white border flex flex-col justify-between p-8">
      <div className='flex-1 overflow-auto overflow-x-clip relative'>
        {data.map((item, index) => (
            <div key={index} className="bg-slate-50 p-4 text-wrap rounded-2xl mt-6">
            <TextTypingEffect  text={item} showCursor={true} speed={3}  />
            {loading && <p>Loading...</p>}
            <div>
                {data.length === index + 1 && <button onClick={handleRegenerate} className="bg-purple text-white px-4 py-2 rounded-xl">Regenerate {index + 1}</button>}
                </div>
          </div>
        ))}
        
      </div>
      <div className="flex gap-x-4 pt-4 z-10 bg-white">
        <input type='text' placeholder='Type your question' value={text} onChange={e => setText(e.target.value)} className="border border-slate-300 rounded-xl w-full p-2" />
        <button onClick={handleSendMessage} className="bg-purple text-white px-4 py-2 rounded-xl">Send</button>
      </div>
    </div>
  );
};
export default ChatAi;
