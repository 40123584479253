import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";

import { ROUTES } from "constants/routes.constants";

import { NumberOrNull } from "interfaces/shared-types.interfaces";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { useLoadBudgets } from "hooks/budgets/useLoadBudgets";
import { useBoolean } from "hooks/useBoolean";

import { selectIsAvailableToCreateWorkflow } from "storage/selectors/user";
import { selectWorkflowsWithIsLoaded } from "storage/selectors/workflows";
import { openPopup } from "storage/slices/popup-slice";
import { getUserPreferences } from "storage/slices/preferences-slice";

import { Modal } from "shared/components/modal/Modal";
import { ModalControlButtons } from "shared/components/modal/ModalControlButtons";
import { DashboardLayout } from "shared/layouts/DashboardLayout";

import { BudgetsTable } from "page-components/budgets/budgets-table/BudgetsTable";
import { AllWorkflows } from "page-components/workflow/all-workflows/AllWorkflows";
import { WorkflowCreateProject } from "page-components/workflow/workflow-create-project/WorkflowCreateProject";

export default function Workflow() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [selectedBudgetId, setSelectedBudgetId] = useState<NumberOrNull>(null);
  const [isWorkflowsExists, setIsWorkflowsExists] = useState<boolean | null>(null);

  const isAvailableToCreateWorkflow = useAppSelector(selectIsAvailableToCreateWorkflow);

  const [isModalOpen, _, handleOpenModal, handleCloseModal] = useBoolean();
  const { projectsList } = useLoadBudgets({});

  const { isWorkflowsLoaded, workflows } = useAppSelector(selectWorkflowsWithIsLoaded);

  const handleSetBudgetId = (value: number) => setSelectedBudgetId(value);

  const handleProceed = () => {
    if (!selectedBudgetId) return;

    navigate(`${ROUTES.workflow}/${selectedBudgetId}`);
  };

  const handleOpenCreateModal = () => {
    if (!isAvailableToCreateWorkflow) {
      dispatch(openPopup("MembershipsModal"));
      return;
    }

    handleOpenModal();
  };

  const handleCreateWorkflow = () => {
    if (projectsList?.length) return handleOpenModal();
    dispatch(openPopup("CreateBudget"));
  };

  useEffect(() => {
    if (isWorkflowsLoaded === null || isWorkflowsExists !== null) return;

    setIsWorkflowsExists(!!(isWorkflowsLoaded && workflows?.length));
    dispatch(getUserPreferences());
  }, [dispatch, isWorkflowsExists, isWorkflowsLoaded, workflows?.length]);
  const filteredProjectList = useMemo(()=>{
    return projectsList.filter(item=> item?.status === "active")
  },[projectsList])
  return (
    <DashboardLayout title='Workflow'>
      <AllWorkflows handleOpenCreateModal={handleOpenCreateModal} hidden={!isWorkflowsExists} />
      {!isWorkflowsExists && isWorkflowsExists !== null && <WorkflowCreateProject onClick={handleCreateWorkflow} />}
      <Modal
        title='Select budget to start'
        description='Our AI intelligence platform can create a new marketing channel budget or help you optimize your existing budget for the year.'
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      >
        <BudgetsTable data={filteredProjectList} selectedId={selectedBudgetId} handleSelectBudgetId={handleSetBudgetId} />
        <ModalControlButtons primaryButtonDisabled={!selectedBudgetId} buttonClass="px-[42px] w-fit" submitButtonText='Proceed' handleSubmit={handleProceed} />
      </Modal>
    </DashboardLayout>
  );
}
