import { useMemo, useState, type FC } from "react";

import { useAppSelector } from "hooks/appHooks";

import { ReactComponent as GrowRateIcon } from "assets/icons/growRateMarketingIcon.svg";
import { ReactComponent as WalletIcon } from "assets/icons/marketingWalletIcon.svg";
import { ReactComponent as RevenueMarketingIcon } from "assets/icons/revenueMarketingIcon.svg";

import { cn } from "lib/utils";

import LoadingOverlay from "../components/LoadingOverlay";

import BrainvoySuggest from "./BrainvoySuggest/BrainvoySuggest";
import BudgetAllocation from "./BudgetAllocation/BudgetAllocation";
import ChatAi from "./ChatAi/ChatAi";
import classes from "./ExecutiveView.module.scss";
import Recomendations from "./Recomendations/Recomendations";
import { RevenueFormattedValue } from "./RevenueFormattedValue";

interface ExecutiveViewProps {
  openChat: boolean;
}

const ExecutiveView: FC<ExecutiveViewProps> = ({ openChat = false }) => {
  const project = useAppSelector(state => state.project.project);
  const totalBudget = useMemo(() => {
    return project?.project_data?.budget_data?.reduce((acc: number, item: any) => {
      item?.channels?.forEach((item: any) => (acc += item.value_new));
      return acc;
    }, 0);
  }, [project]);

  return (
    <div className={cn("!py-4 !px-6 flex flex-col overflow-x-hidden w-full")}>
      <div className='relative w-full grid grid-cols-1 md:grid-cols-3 md:gap-4'>
        <AnalyticCard>
          <div className='flex justify-between'>
            <div className='flex flex-col gap-y-2'>
              <p className='text-[#5E636E] text-sm font-normal'>Marketing Budget</p>
              <p className='text-[#14191A] text-2xl font-semibold '>${totalBudget?.toLocaleString("en-US")}</p>
            </div>
            <WalletIcon className='self-start' />
          </div>
          <AnalyticCardRuler />
          <div className='flex justify-between'>
            <div className='flex flex-col gap-1'>
              <p className='text-[#717684] font-medium text-xs'>Previous Month</p>
              <h3>-</h3>
            </div>
            <div className='flex flex-col gap-1'>
              <p className='text-[#717684] font-medium text-xs'>Previous Year</p>
              <h3>-</h3>
            </div>
          </div>
        </AnalyticCard>
        <AnalyticCard>
          <div className='flex justify-between'>
            <div className='flex flex-col gap-y-2'>
              <p className='text-[#5E636E] text-sm font-normal'>Projected Revenue</p>

              <RevenueFormattedValue
                className='[&_span]:!text-[#14191A] [&_span]:!text-2xl [&_span]:!font-semibold m-0'
                from={project?.revenue_from}
                to={project?.revenue_to}
              />
            </div>
            <RevenueMarketingIcon className='self-start' />
          </div>
          <AnalyticCardRuler />
          <div className='flex justify-between'>
            <div className='flex flex-col gap-1'>
              <p className='text-[#717684] font-medium text-xs'>Previous Month</p>
              <h3>-</h3>
            </div>
            <div className='flex flex-col gap-1'>
              <p className='text-[#717684] font-medium text-xs'>Previous Year</p>
              <h3>-</h3>
            </div>
          </div>
        </AnalyticCard>
        <AnalyticCard>
          <div className='flex justify-between'>
            <div className='flex flex-col gap-y-2'>
              <p className='text-[#5E636E] text-sm font-normal'>Revenue Growth Rate</p>

              <p className='text-[#14191A] text-2xl font-semibold'>
                {project?.revenue_rate ? `${project?.revenue_rate || "--"}% YoY` : <span className='text-[#9EA2AD]'>Not Provided</span>}
              </p>
            </div>
            <GrowRateIcon className='self-start' />
          </div>
          <AnalyticCardRuler />
          <div className='flex justify-between'>
            <div className='flex flex-col gap-1'>
              <p className='text-[#717684] font-medium text-xs'>Previous Month</p>
              <h3>-</h3>
            </div>
            <div className='flex flex-col gap-1'>
              <p className='text-[#717684] font-medium text-xs'>Previous Year</p>
              <h3>-</h3>
            </div>
          </div>
        </AnalyticCard>
      </div>
      <BrainvoySuggest />
      <BudgetAllocation openChat={openChat} />
      <ChatAi />
    </div>
  );
};

const AnalyticCard = ({ className, children }: { children: React.ReactNode; className?: string }) => {
  return <div className={cn("flex flex-col h-[140px] justify-between bg-white rounded-2xl pt-3 px-6 pb-2.5", className)}>{children}</div>;
};

const AnalyticCardRuler = () => {
  return <div className=' h-[1px] bg-[#F7F7F8] w-full'></div>;
};

export default ExecutiveView;
