import { createAsyncThunk } from "@reduxjs/toolkit";

import { apiGetMyPersonalizationData, apiGetPersonalizationAllOptionsList, apiSaveMyPersonalizationData } from "api/personalization";

import { SavePersonalizationPayload } from "interfaces/personalization.interfaces";

import { showServerError } from "utils/modules.utils";

export const PERSONALIZATION_SLICE_NAME = "personalization";

export const getMyPersonalizationData = createAsyncThunk(
  `${PERSONALIZATION_SLICE_NAME}/getMyPersonalizationData`,
  async () => await apiGetMyPersonalizationData(),
);

export const getPersonalizationOptionsList = createAsyncThunk(`${PERSONALIZATION_SLICE_NAME}/getPersonalizationOptionsList`, () =>
  apiGetPersonalizationAllOptionsList(),
);

export const saveMyPersonalizationData = createAsyncThunk(
  `${PERSONALIZATION_SLICE_NAME}/saveMyPersonalizationData`,
  (payload: SavePersonalizationPayload, { rejectWithValue }) => {
    try {
      return apiSaveMyPersonalizationData(payload);
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);
