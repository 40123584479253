import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TaskItem } from "api/task/type";

import { getTasksListReducer } from "storage/reducers/task";

interface PaginationMetadata {
  page: number;
  totalPages: number;
  totalItems: number;
}
export interface TaskState {
  task: TaskItem | null;
  taskList: TaskItem[];

  pagination: PaginationMetadata;
}

const initialState: TaskState = {
  task: null,
  taskList: [],

  pagination: {
    page: 1,
    totalPages: 0,
    totalItems: 0,
  },
};

export const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    setTask: (state, action: PayloadAction<TaskItem | null>) => {
      state.task = action.payload;
    },
  },
  extraReducers: builder => {
    getTasksListReducer(builder);
  },
});

export const { setTask } = taskSlice.actions;
export const taskReducer = taskSlice.reducer;
