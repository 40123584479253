import { createContext, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getUsersCompanyProfile } from "api/companies";
import { apigetPersonalizationUrl, savePersonalizationUrl } from "api/personalization";

import { companyCreationEvent, extractUserDetails } from "utils/analytics.utils";

import { useAppSelector } from "hooks/appHooks";

import { setIsCollapsed } from "storage/slices/sidebar-slice";

import { Button } from "shared/components/buttons/Button";
import Alert from "shared/components/toasts";
import { DashboardLayout } from "shared/layouts/DashboardLayout";

import { ReactComponent as BackIcon } from "assets/icons/arrow-left-purple.svg";

import CompanyBrandTone from "./components/CompanyBrandTone";
import CompanyDescription from "./components/CompanyDescription";
import Companyindustry from "./components/CompanyIndustry";
import CompanyType from "./components/CompanyType";
import InputContainer from "./components/InputContainer";

export const SetupCompanyProfileContext = createContext<undefined | any>(undefined);

const SetupCompanyProfile = () => {
  const [data, setData] = useState <any>(null);
  const [url, setUrl] = useState("")
  const [apiData, setApiData] = useState({
      description: "",
      company_type: "",
    industry: [],
    brandtone: [],

  });
  const [loading, setLoading] = useState(false);
  const [showRestartButton, setShowRestartButton] = useState(false);
  const [saving, setSaving] = useState(false);
  const user = useAppSelector(state => state.auth?.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  const getAiGeneratedProfile = async (url: string) => {
    setLoading(true);
    try {
      const response = await apigetPersonalizationUrl(url.trim());
      setLoading(false);
      console.log("Response",response)
      if(response){
        setData(response)
        setApiData({
          description: response?.description,
          company_type: response?.business_model[0],
          industry: response?.industries?.slice(0, 2),
          brandtone: response?.brand_tone?.slice(0, 4),
        })
      }
    } catch (err : unknown) {
        setLoading(false);
        if(err instanceof Error)
        Alert("error", err?.message, "Error")
    }
  };

  const handleImport = useCallback((url: string) => {
    getAiGeneratedProfile(url);
  }, []);
  const updateShowRestartButton = useCallback((value:boolean)=>{
    setShowRestartButton(value)
  },[])
  const updateApiData = useCallback((data: any) => {
    setApiData(data);
  }, []);
  const updateUrl = useCallback((value:string)=>{
    setUrl(value)
  },[])

  const getPerviouslySavedData = async()=>{
    try{
      const response = await getUsersCompanyProfile()
      if(response){
        let business_model = response?.business_model
        if(!business_model){
          business_model = []
        }else{
          business_model = [business_model]
        }
        setData({...response,business_model:[]})
        setApiData({
          description: response?.description,
          company_type: response?.business_model ?? "",
          industry: response?.industries?.slice(0, 2),
          brandtone: response?.brand_tone?.slice(0, 4),
        })
        updateUrl(response?.website_url)
        updateShowRestartButton(true)
      }
    }catch(err){
      console.log("Error in getPerviouslySavedData",err)
    }
  }
  useEffect(() => {
    getPerviouslySavedData()
  }, []);

  const resetData =useCallback(()=> {
    setData(null)
    setApiData({
      description: "",
      company_type: "",
    industry: [],
    brandtone: [],
    })
  },[])
  const handleSave = () => {
    setSaving(true)
    const api = {
      ...data,
      description: apiData.description,
      business_model: apiData.company_type,
      industries: apiData.industry,
      brand_tone: apiData.brandtone,
    }
    console.log("Api",JSON.stringify(api))
    savePersonalizationUrl(api).then(res=>{
      if(res){
        Alert("success","Company profile saved successfully","Success")
        companyCreationEvent(extractUserDetails(user))
        navigate(-1)
      }
    }).catch(res=>{
      Alert("error",res.message,"Error")
    }).finally(()=>{
      setSaving(false)
    })
  };
  const continueBtnDisabled = !apiData.description || !apiData.company_type || !apiData.industry.length || !apiData.brandtone.length || loading || saving;
  return (
    <DashboardLayout title='Get Started'>
      <div className='px-6 pt-4'>
        <div className='flex flex-col gap-y-4'>
          {/* --------------- Back Button --------------------- */}
          <button className='flex items-center ' onClick={handleBack}>
            <BackIcon className='mr-2.5 h-5 w-5' />
            <p className='text-base font-semibold text-purple'>Back</p>
          </button>
          {/* --------------- Input Container --------------------- */}
          <SetupCompanyProfileContext.Provider value={{ data,url, updateUrl, handleImport, loading, apiData, updateApiData,updateShowRestartButton,showRestartButton,resetData }}>
            <InputContainer />
            <CompanyDescription />
            <div className='grid gap-x-4 grid-cols-4'>
              <CompanyType />
              <Companyindustry />
            </div>
            <CompanyBrandTone />
            <div className='flex justify-end mt-[5%] '>
              <Button className='!w-fit px-[42px] rounded-xl' disabled={continueBtnDisabled} onClick={handleSave}>
                <p>{saving? "Saving...":"Continue"}</p>
              </Button>
            </div>
          </SetupCompanyProfileContext.Provider>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SetupCompanyProfile;
