import { createAsyncThunk } from "@reduxjs/toolkit";
import { Dispatch } from "redux";

import { getAllTasks } from "api/task";

export const TASK_SLICE_NAME = "tasks";

export const getTasksList = createAsyncThunk(`${TASK_SLICE_NAME}/getTasksList`, ({ page }: { page: number }) => {
  return getAllTasks(page);
});
