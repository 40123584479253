import ReactGA from "react-ga4";

// ReactGA.event({
//     category: "your category",
//     action: "your action",
//     label: "your label", // optional
//     value: 99, // optional, must be a number
//     nonInteraction: true, // optional, true/false
//     transport: "xhr", // optional, beacon/xhr/image
//   });
type userDetailsType = {
  id: number;
};
export const extractUserDetails = (user:any) : userDetailsType=>{
  return {
    id: user?.uuid ?? 0,
  }
}

export const loginEvent = (user: userDetailsType) => {
  ReactGA.event("log_in_rate",{
    category: "Auth",
    action: "Login",
    label: "Log in Rate",
    ...user
  });
};

export const signupEvent = (user: userDetailsType) => {
  ReactGA.event("sign_up_rate",{
    category: "Auth",
    action: "Signup",
    label: "Sign up Rate",
    ...user
  });
};

export const ctaEvent = (action: string,user: userDetailsType) => {
  ReactGA.event("cta_tracking",{
    category: "CTA",
    action: action,
    label: "CTA tracking",
    ...user
  });
};

export const popupEvent = (action: string,user: userDetailsType) => {
  ReactGA.event("pop_up_view",{
    category: "Pop up",
    action: action,
    label: "Pop up view",
    ...user
  });
};

export const workflowCreationEvent = (user: userDetailsType) => {
  ReactGA.event("workflow_creation",{
    category: "Workflow",
    action: "Create Workflow",
    label: "Workflow creation",
    ...user
  });
};
export const budgetCreationEvent = (label:"active"|"inactive" ,user: userDetailsType) => {
  ReactGA.event("budget_creation",{
    category: "Budget",
    action: "Create Budget",
    label: label,
    ...user
  });
};
export const companyCreationEvent = (user: userDetailsType) => {
  ReactGA.event("company_profile_creation",{
    category: "Company profile",
    action: "Create Company profile",
    label: "Company profile creation",
    ...user
  });
};
