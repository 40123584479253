import { createSlice } from "@reduxjs/toolkit";

import { Strategy, Workflow, WorkflowListItem } from "interfaces/workflows.interfaces";

import {
  creatingWorkflowReducer,
  generateWorkflowReducer,
  getAllStrategyReducer,
  getAllWorkflowsReducer,
  getWorkflowReducer,
  setIsWorkflowsLoadedReducer,
  setWorkflowReducer,
} from "../reducers/workflows";

export interface WorkflowsState {
  isGeneratingContent: boolean;
  workflow: Workflow | null;
  workflows: WorkflowListItem[] | null;
  isWorkflowsLoaded: boolean | null;
  isWorkflowLoading: boolean;
  strategies: Strategy[] | null;
  isStrategyLoading: boolean;
}

const initialState: WorkflowsState = {
  isGeneratingContent: false,
  workflow: null,
  workflows: null,
  isWorkflowsLoaded: null,
  isWorkflowLoading: false,
  strategies: null,
  isStrategyLoading: false,
};

export const workflowsSlice = createSlice({
  name: "workflows",
  initialState,
  reducers: {
    removeSelectedWorkflow: state => {
      state.workflow = null;
    },
  },
  extraReducers: builder => {
    setWorkflowReducer(builder);
    generateWorkflowReducer(builder);
    creatingWorkflowReducer(builder);
    getAllWorkflowsReducer(builder);
    setIsWorkflowsLoadedReducer(builder);
    getWorkflowReducer(builder);
    getAllStrategyReducer(builder);
  },
});

export const { removeSelectedWorkflow } = workflowsSlice.actions;

export const workflowsReducer = workflowsSlice.reducer;
