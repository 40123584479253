import  { useState, useEffect } from "react";

function TextTypingEffect({ text, speed = 50,showCursor = false }) {
  const [displayText, setDisplayText] = useState("");
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (index < text.length) {
      const timeout = setTimeout(() => {
        setDisplayText((prev) => prev + text[index]);
        setIndex((prev) => prev + 1);
      }, speed);
      return () => clearTimeout(timeout);
    }
  }, [index, text, speed]);

  return <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }} className="text-wrap ">{displayText}{showCursor && <p style={{display: "inline",animation: "blink 1s infinite",}}>|</p>}</pre>;
}

export default TextTypingEffect