import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SidebarState {
  isCollapsed: boolean;
  dropdownOpenList: string[];
}

const initialState: SidebarState = {
  isCollapsed: false,
  dropdownOpenList: [],
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setIsCollapsed: (state, action: PayloadAction<boolean>) => {
      state.isCollapsed = action.payload;
    },
    setDropdownOpenList: (state, action: PayloadAction<string[]>) => {
      state.dropdownOpenList = action.payload;
    },
  },
});

export const { setIsCollapsed, setDropdownOpenList } = sidebarSlice.actions;
export const sidebarReducer = sidebarSlice.reducer;
