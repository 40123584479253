import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "constants/routes.constants";

import { useAppDispatch, useAppSelector } from "hooks/appHooks";
import { useSorting } from "hooks/useSorting";

import { getAllConnectedSources } from "storage/slices/integrations-slice";

import { SortDropdown } from "shared/components/SortDropdown";
import { DashboardLayout } from "shared/layouts/DashboardLayout";

import { ConnectedTable } from "./components/ConnectedTable";

export default function ConnectedSources() {
  const dispatch = useAppDispatch();
  const sortingData = useSorting();
  const navigate = useNavigate();
  const connectedSourcesList = useAppSelector(state => state.integrations.connectedSources);

  const filteredData = useMemo(() => {
    console.log("sortingData", sortingData.sorting);
    return connectedSourcesList
      .map(item => ({ ...item, ...item.integration_data }))
      .sort((a: any, b: any) => {
        if (sortingData.sorting.id === "oldest") {
          return a.date_created - b.date_created;
        } else {
          return b.date_created - a.date_created;
        }
      });
  }, [connectedSourcesList, sortingData.sorting]);
  useEffect(() => {
    dispatch(getAllConnectedSources())
      .unwrap()
      .then(res => {
        if (res.length === 0) {
          // Uncomment below line when connect sources page is ready
          navigate(ROUTES.connectSources);
        }
      });
  }, []);
  return (
    <DashboardLayout title='Integrations'>
      <div className='bg-[#F7F9FB] rounded-xl flex-1 min-h-full flex flex-col items-start gap-y-6 py-6 px-5'>
        <div className='w-full bg-white rounded-2xl p-6'>
          <div className='flex justify-between items-center'>
            <div className='flex items-center space-x-6 mb-4'>
              <h4 className='text-lg font-bold text-[#1F2228]'>Connected Sources</h4>
              {/*TODO - uncomment*/}
              {/*<div className='h-[23px] w-px bg-[#E8E9F0]' />*/}
              {/*<button className='bg-white border border-[#E9EAEC] rounded-full p-2' onClick={handleOpenDeleteModal}>*/}
              {/*  <TrashIcon className='text-[#717684]' />*/}
              {/*</button>*/}
            </div>
            <SortDropdown {...sortingData} />
          </div>
          <ConnectedTable data={filteredData} selectedId={null} />
        </div>
      </div>
    </DashboardLayout>
  );
}
